// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { useCallback } = React;

const { TextField } = require('@andes/textfield');
const { SplitTextfield } = require('@andes/textfield');

const UnloggedForm = ({ errors, onSetErrors, onSetEmail, onSetName, onSetPhone }) => {
  const validateEmail = useCallback(
    (event) => {
      const emailInput = event.target.value;

      if (emailInput.length <= 0 || emailInput.length <= 6 || emailInput.indexOf('@') <= 0) {
        onSetErrors([...errors, 'MAIL']);

        return;
      }

      onSetErrors(errors.filter((er) => er !== 'MAIL') || []);
    },
    [errors, onSetErrors],
  );

  const validateName = useCallback(
    (event) => {
      const target = event.target.value;

      if (target.length === 0 || target.length <= 6 || !target.includes(' ')) {
        onSetErrors([...errors, 'NAME']);
      } else if (errors.includes('NAME')) {
        onSetErrors(errors.filter((er) => er !== 'NAME') || []);
      }

      onSetName(target);
    },
    [errors, onSetErrors, onSetName],
  );

  const validatePhone = useCallback(
    (event) => {
      const field = event.target.value.replace(/\D+/g, '');
      const validateValue = /^[0-9]{8}$/.test(field);

      if (!validateValue) {
        onSetErrors([...errors, 'PHONE']);
      } else if (field.length === 0 || field.length < 8) {
        onSetErrors([...errors, 'PHONE']);
      } else if (errors.includes('PHONE')) {
        onSetErrors(errors.filter((er) => er !== 'PHONE') || []);
      }

      onSetPhone(event.target.value);
    },
    [errors, onSetErrors, onSetPhone],
  );

  return (
    <div className="form__fields">
      <TextField
        helper={errors.includes('NAME') ? 'Revisa tu nombre y apellido.' : ''}
        label="Nombre y apellido"
        modifier={errors.includes('NAME') ? 'error' : 'default'}
        placeholder="Tu nombre y apellido"
        onChange={(event) => validateName(event)}
      />
      <TextField
        helper={errors.includes('MAIL') ? 'Revisa tu e-mail.' : ''}
        label="E-mail"
        modifier={errors.includes('MAIL') ? 'error' : 'default'}
        placeholder="Tuemail@ejemplo.com"
        onBlur={async (event) => {
          const emailInput = event.target.value;

          if (emailInput.length <= 0 && !errors.includes('MAIL')) {
            return;
          }

          onSetEmail(emailInput);
        }}
        onChange={(event) => validateEmail(event)}
      />
      <SplitTextfield
        helper={errors.includes('PHONE') && 'Revisa tu número de celular.'}
        label="Número de celular"
        maxLength={8}
        modifier={errors.includes('PHONE') ? 'error' : 'default'}
        placeholder="12345678"
        splitLabel="+569"
        onChange={(event) => validatePhone(event)}
      />
    </div>
  );
};

UnloggedForm.defaultProps = {
  errors: [],
  onSetErrors: undefined,
  onSetEmail: undefined,
  onSetName: undefined,
  onSetPhone: undefined,
};

UnloggedForm.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.any),
  onSetErrors: PropTypes.func,
  onSetEmail: PropTypes.func,
  onSetName: PropTypes.func,
  onSetPhone: PropTypes.func,
};

module.exports = UnloggedForm;
