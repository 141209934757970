// i18nLocalesOverride:['pr-BR']

const React = require('react');

const { useI18n } = require('nordic/i18n');

const Tyc = () => {
  const { i18n } = useI18n();

  return (
    <div className="tyc-section-wrapper">
      <div>
        <p>
          {i18n.jsx.gettext(
            'Los incentivos serán pagados de acuerdo con tu desempeño y con el cumplimiento de los {0}Términos y condiciones.{1}',
            {
              tags: {
                0: '<a href="https://www.mercadopago.com.br/ajuda/regulamento-programa-parceiros-mercado-pago_4383" target="_blank" rel="noopener noreferrer" />',
                1: '</a>',
              },
              wrapper: 'span',
            },
          )}
        </p>
      </div>
    </div>
  );
};

module.exports = Tyc;
