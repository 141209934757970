/* eslint-disable import/order */

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/useFormContext');

const { Button } = require('@andes/button');
const { Pill } = require('@andes/badge');
const { Text, Title } = require('@andes/typography');

const PillEmailLogged = require('../../PillEmailLogged');

const tracking = require('../../../../../../../services/utils/tracking');

const { INVITE_AND_WIN } = require('../../../../../../../services/constants/externalUrls');

const ErrorAccountBlocked = () => {
  const { i18n } = useI18n();

  const { user, isWebview, siteId } = useFormContext();

  const { email } = user;

  const handleButtonClick = () => {
    tracking('home', 'form-register-error-account-blocked-redicrect-mgm');

    window.open(INVITE_AND_WIN[siteId], '_self');
  };

  return (
    <div className="error-account-blocked">
      <div className="error-account-blocked__header">
        <Pill color="red" contentType="icon" size="small" />
        <Title color="negative" component="h6" size="s">
          {i18n.gettext('Cuenta bloqueada en el programa')}
        </Title>
      </div>
      <Text component="p">
        {isWebview
          ? i18n.jsx.gettext(
              'La cuenta asociada a {2} está bloqueada. Para seguir ofreciendo lectores Point,{0}conoce el programa Invita y gana.{1}',
              {
                tags: {
                  0: "<span class='semibold'>",
                  1: '</span>',
                },
                replacements: {
                  2: email,
                },
              },
            )
          : i18n.jsx.gettext(
              'Para seguir ofreciendo lectores Point, {0}conoce el programa Invita y gana.{1}',
              {
                tags: {
                  0: "<span class='semibold'>",
                  1: '</span>',
                },
              },
            )}
      </Text>
      {!isWebview && <PillEmailLogged />}
      <div className="error-account-blocked-actions">
        <Button fullWidth onClick={handleButtonClick}>
          {i18n.gettext('Conocer más')}
        </Button>
      </div>
    </div>
  );
};

module.exports = ErrorAccountBlocked;
