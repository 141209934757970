// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { Image } = require('nordic/image');
const { Typography } = require('@andes/typography');

const CardDivider = require('../../Atoms/CardDivider');
const DiscoverItem = require('../ItemDetails');

const configs = require('./config');

const Discover = ({ siteId = 'MLA', devices = [] }) => {
  const { i18n } = useI18n();
  const { title, imageSrc, items } = configs(siteId, devices, i18n);

  return (
    <div className={`discover__wrapper ${siteId?.toLowerCase()}`}>
      {siteId === 'MLC' && <CardDivider />}
      <div className="discover">
        <div className="discover-content">
          <Typography component="h3" size="l" type="title">
            {title}
          </Typography>
          <div className="discover-image">
            <Image src={imageSrc} />
          </div>
          {items.map((item) => (
            <DiscoverItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

Discover.propTypes = {
  siteId: PropTypes.string,
  devices: PropTypes.arrayOf(PropTypes.shape({})),
};

module.exports = Discover;
