// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const Button = require('@andes/button');
const Add16 = require('@andes/icons/Add16');
const TextField = require('@andes/textfield');
const Typography = require('@andes/typography');
const Substract16 = require('@andes/icons/Substract16');

const { formatterPrice } = require('../../../../../../services/utils/formatter');

const { CALC_COLS } = require('./configs');

const DevicesTable = ({ devices, devicesTotals, onChangeQuantity, onSetDevicesTotals }) => {
  return (
    <>
      <div className="row">
        <Typography className="cell" size="xl" />
        <Typography className="cell bold quantity-title" size="xl">
          Cantidad
        </Typography>
        {CALC_COLS.map((c) => (
          <Typography key={c.title} className="cell bold" size="xl">
            {c.title}
          </Typography>
        ))}
      </div>

      {devices &&
        devices.map((d) => {
          const device = devicesTotals?.find((dev) => d.id === dev.id) || {};

          return (
            <div key={d.model} className="row">
              <Typography className="cell bold" size="xl">
                {d.model}
              </Typography>

              <TextField
                centered
                disabled
                className="cell"
                defaultValue={device?.quantity || 0}
                min={0}
                type="number"
                value={device?.quantity || 0}
              >
                <Button
                  hierarchy="transparent"
                  size="small"
                  onClick={() => {
                    if (device?.quantity - 1 < 0) {
                      return;
                    }

                    device.quantity -= 1;

                    Object.keys(d.prizes).forEach((p) => {
                      device[p?.toString()] = +d?.prizes[p?.toString()] * +device?.quantity;
                    });

                    onSetDevicesTotals([...devicesTotals]);

                    onChangeQuantity(devicesTotals);
                  }}
                >
                  <Substract16 color="#009EE3" />
                </Button>

                <Button
                  hierarchy="transparent"
                  size="small"
                  onClick={() => {
                    device.quantity += 1;

                    Object.keys(d?.prizes).forEach((p) => {
                      device[p?.toString()] = +d?.prizes[p?.toString()] * +device?.quantity;
                    });

                    onSetDevicesTotals([...devicesTotals]);
                    onChangeQuantity(devicesTotals);
                  }}
                >
                  <Add16 color="#009EE3" />
                </Button>
              </TextField>

              {CALC_COLS.map((c) => (
                <Typography
                  key={`${d?.model}-${device?.quantity}-${c?.field}`}
                  className="cell"
                  color={+device.quantity === 0 ? 'disabled' : 'primary'}
                  size="xl"
                >
                  {formatterPrice(device[c.field.toString()]?.toFixed(2))}
                </Typography>
              ))}
            </div>
          );
        })}
    </>
  );
};

DevicesTable.defaultProps = {
  devices: [],
  devicesTotals: [],
  onChangeQuantity: () => {},
  onSetDevicesTotals: () => {},
};

DevicesTable.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      model: PropTypes.string,
      prizes: PropTypes.shape({
        profit: PropTypes.number,
        activation: PropTypes.number,
      }),
    }),
  ),
  devicesTotals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      quantity: PropTypes.number,
    }),
  ),
  onChangeQuantity: PropTypes.func,
  onSetDevicesTotals: PropTypes.func,
};

module.exports = DevicesTable;
