/* eslint-disable import/order */

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/useFormContext');

const { Button } = require('@andes/button');
const { Pill } = require('@andes/badge');
const { Text, Title } = require('@andes/typography');

const tracking = require('../../../../../../../services/utils/tracking');

const Error = () => {
  const { onSetApiResponseType } = useFormContext();

  const { i18n } = useI18n();

  const handleButtonClick = () => {
    tracking('home', 'form-register-error');

    onSetApiResponseType('');
  };

  return (
    <div className="error-register">
      <div>
        <Pill color="red" contentType="icon" />
        <Title component="h6" size="s">
          {i18n.pgettext('resellers-landing', 'Algo salió mal')}
        </Title>
      </div>
      <Text component="p">
        {i18n.gettext(
          'No fue posible finalizar tu registro. Por favor, inténtalo de nuevo o vuelve más tarde.',
        )}
      </Text>
      <Button fullWidth onClick={handleButtonClick}>
        {i18n.gettext('Intentar nuevamente')}
      </Button>
    </div>
  );
};

module.exports = Error;
