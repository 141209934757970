/* eslint-disable import/order */

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/useFormContext');

const { Button } = require('@andes/button');
const { Pill } = require('@andes/badge');
const { Text, Title } = require('@andes/typography');

const PillEmailLogged = require('../../PillEmailLogged');

const { getExternalURLs } = require('../../../../../../../services/utils/navigation');

const tracking = require('../../../../../../../services/utils/tracking');

const ErrorAccountDuplicated = () => {
  const { i18n } = useI18n();

  const { user, siteId, traficOrigin, isWebview, onSetApiResponseType } = useFormContext();
  const { email, logged } = user;

  const externalUrls = getExternalURLs(siteId, traficOrigin);

  const redirect = () => {
    tracking('home', 'form-register-error-account-duplicated');

    window.open(externalUrls.MICROSITIO, '_self');
  };

  const registerWithOtherAccount = () => onSetApiResponseType('');

  return (
    <div className="error-account-duplicated">
      <div className="error-account-duplicated__header">
        <Pill color="orange" contentType="icon" size="small" />
        <Title component="h6" size="s">
          {i18n.gettext('Registro existente')}
        </Title>
      </div>
      <Text component="p">
        {isWebview || !logged
          ? i18n.gettext(
              'El e-mail {0} ya está registrado. Accede a tu portal y aprovecha tus beneficios.',
              email,
            )
          : i18n.gettext(
              'Ya formas parte del programa. Accede a tu portal y aprovecha los beneficios.',
            )}
      </Text>
      {!isWebview && logged && <PillEmailLogged />}
      <div
        className={`error-account-duplicated__actions ${logged ? 'logged' : ''} ${
          isWebview ? 'webview' : ''
        }`}
      >
        <Button fullWidth onClick={redirect}>
          {i18n.gettext('Ir al portal')}
        </Button>
        {!logged && (
          <Button fullWidth hierarchy="transparent" onClick={registerWithOtherAccount}>
            {i18n.gettext('Volver al formulario')}
          </Button>
        )}
      </div>
    </div>
  );
};

module.exports = ErrorAccountDuplicated;
