// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Text, Title } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');
const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

const CardDivider = require('../../../../Atoms/CardDivider');

const configs = require('./config');

const Benefits = ({ deviceType = 'mobile' }) => {
  const { i18n } = useI18n();

  const { title, items, image } = configs(i18n, deviceType);

  const imageComponent = (
    <div className="benefits-wrapper__container-image">
      <Image
        alt={image.alt}
        src={image.src}
        srcFallback={image.srcFallback}
        width={deviceType === 'mobile' ? 353 : 510}
      />
    </div>
  );

  return (
    <div className="benefits-wrapper">
      <div className="benefits-wrapper__container">
        <div>
          <CardDivider />
          <Title component="h2" type="title">
            {title}
          </Title>
          {deviceType === 'mobile' && imageComponent}
          <div>
            {items.map((item) => (
              <div key={item.title} className="benefits-wrapper__container-item">
                <FeedbackPositive24 color="#009EE3" />
                <Text>{item.title}</Text>
              </div>
            ))}
          </div>
        </div>
        {deviceType !== 'mobile' && imageComponent}
      </div>
    </div>
  );
};

Benefits.propTypes = {
  deviceType: PropTypes.string,
};

module.exports = Benefits;
