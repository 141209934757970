// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Title } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');

const CardDivider = require('../../../../Atoms/CardDivider');
const SliderCard = require('../../../../Organisms/Shared/SliderCard');

const configs = require('./config');

const Steps = ({ deviceType = 'mobile' }) => {
  const { i18n } = useI18n();

  const { title, items } = configs(i18n);

  const imageSize = deviceType === 'mobile' ? { width: 288 } : { width: 240, height: 152 };

  return (
    <div className="steps-wrapper">
      <div className="steps-wrapper__container">
        <CardDivider />
        <Title component="h2" size="l" type="title">
          {title}
        </Title>
        <SliderCard
          label="Seja um revendedor em 4 passos"
          type="transparent"
          {...{ deviceType, imageSize, items }}
        />
      </div>
    </div>
  );
};

Steps.propTypes = {
  deviceType: PropTypes.string,
};

module.exports = Steps;
