const getMax = (items, field) => {
  if (!items || items?.length === 0) {
    return 0;
  }

  return Math.max(...items.map((item) => item[field] || 0));
};

module.exports = {
  getMax,
};
