// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const Card = require('@andes/card');
const Typography = require('@andes/typography');
const { Tab, Tabs, TabContent } = require('@andes/tabs');

const CardDivider = require('../../../../Atoms/CardDivider');
const tracking = require('../../../../../../services/utils/tracking');
const { getDevicesConfigs } = require('../../../../../../services/devices');

const Footer = require('./footer');
const DevicesTable = require('./devicesTable');

const Calculator = ({ groups }) => {
  const [devices, setDevices] = React.useState(undefined);
  const [devicesSelecteds, setDevicesSelecteds] = React.useState([]);
  const [devicesTotals, setDevicesTotals] = React.useState(undefined);

  const selectedLevel = groups[0]?.id;

  const handleChangeQuantity = React.useCallback((totals) => {
    tracking('home', 'calculator-change-quantity');

    setDevicesSelecteds(totals);
  }, []);

  const fetchConfigs = React.useCallback(
    async (id = selectedLevel) => {
      const resp = await getDevicesConfigs(id);

      const devs = resp.data?.devices
        ?.sort((a, b) => a.price_with_discount - b.price_with_discount)
        ?.map((d) => ({
          model: d.model,
          id: d.id,
          prizes: {
            usage:
              resp.data?.configs?.find((c) => c.device_id === d.id)?.usage_point_earning_amount ||
              0,
            activation:
              resp.data?.configs?.find((c) => c.device_id === d.id)
                ?.device_activation_earning_amount ||
              resp.data?.configs?.find((c) => c.device_id === d.id)?.device_activation_amount ||
              0,
            profit: d.fake_price - d.price_with_discount,
            price: d.price_with_discount,
          },
        }));

      const originalDevicesQty = resp.data?.devices?.map((d) => {
        const deviceTotal = devicesSelecteds?.find((dev) => d.id === dev.id);
        const devicePrize = devs?.find((dev) => d.id === dev.id).prizes;

        return {
          id: d.id,
          model: d.model,
          price: deviceTotal?.quantity * devicePrize?.price || 0,
          quantity: deviceTotal?.quantity || 0,
          usage: deviceTotal?.quantity * devicePrize?.usage || 0,
          profit: deviceTotal?.quantity * devicePrize?.profit || 0,
          activation: deviceTotal?.quantity * devicePrize?.activation || 0,
        };
      });

      setDevicesTotals(originalDevicesQty);
      setDevices(devs);
    },
    [devicesSelecteds, selectedLevel],
  );

  React.useEffect(() => fetchConfigs(), [fetchConfigs]);

  return devices && devices.length > 0 ? (
    <div className="calculator__wrapper">
      <Card className="calculator__container">
        <CardDivider />
        <div className="calculator">
          <Typography component="h3" size="m" type="title">
            Calcula tus ganancias
          </Typography>
          <Typography color="secondary">
            Selecciona la cantidad de lectores y conoce tus ganancias vendiendo al precio sugerido:
          </Typography>

          <div className="calculator__content">
            <Tabs srLabel="Default tabs">
              {groups.map((tab) => (
                <Tab
                  key={tab.id}
                  title={tab.name}
                  onClick={() => {
                    tracking('home', `calculator-tab-${tab.name}`);

                    fetchConfigs(tab.id);
                  }}
                >
                  <TabContent>
                    <Card className="calculator__card">
                      <DevicesTable
                        devices={devices}
                        devicesTotals={devicesTotals}
                        onChange={handleChangeQuantity}
                        onSetDevicesTotals={setDevicesTotals}
                      />
                      <Footer devicesTotals={devicesTotals} />
                    </Card>
                  </TabContent>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </Card>
    </div>
  ) : (
    ''
  );
};

Calculator.defaultProps = {
  groups: [],
};

Calculator.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
};

module.exports = Calculator;
