// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const Typography = require('@andes/typography');

const CardDivider = require('../../../Atoms/CardDivider');

const configs = require('./config');

const Video = ({ siteId }) => {
  const { i18n } = useI18n();
  const config = configs(siteId, i18n);

  return (
    <div className={`resellers-info ${siteId?.toLowerCase()}`}>
      {siteId === 'MLC' && <CardDivider />}
      <Typography component="h3" size="l" type="title">
        {config.title}
      </Typography>
      {config.subtitle && (
        <Typography component="p" size="xl">
          {config.subtitle}
        </Typography>
      )}
      <div className="resellers-content">
        <iframe
          // eslint-disable-next-line react/no-unknown-property
          allowFullScreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          className="youtube"
          frameBorder="0"
          loading="lazy"
          src={config.videoSrc}
          title="youtube"
        />
      </div>
    </div>
  );
};

Video.defaultProps = {
  siteId: 'MLA',
};

Video.propTypes = {
  siteId: PropTypes.string,
};

module.exports = Video;
