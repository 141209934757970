// i18nLocalesOverride:['es-CL']

const React = require('react');

const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

module.exports = (devices, levelConfigs, i18n) => {
  return {
    title: i18n.gettext('Accede a nuevos beneficios en cada nivel'),
    imageSrc: 'levels.png',
    items: [
      {
        icon: <FeedbackPositive24 color="#009EE3" />,
        title: i18n.gettext('Nivel Emprendedor'),
        bullets: [
          {
            text: i18n.gettext(
              'Gana hasta {0} al revender Point Smart y hasta {1} por Point Mini.',
              '$ 55.000',
              '$ 9.000',
            ),
          },
          {
            text: i18n.jsx.gettext('Gana hasta {2} con el {0}premio de activación.{1}', {
              tags: {
                0: '<a href="#benefits">',
                1: '</a>',
              },
              replacements: {
                2: '$ 17.000',
              },
            }),
          },
        ],
      },
      {
        icon: <FeedbackPositive24 color="#009EE3" />,
        title: i18n.gettext('Nivel Profesional'),
        bullets: [
          {
            text: i18n.gettext('Mantén el margen de ganancia por cada venta.'),
          },
          {
            text: i18n.jsx.gettext('Gana hasta {2} con el {0}premio de activación.{1}', {
              tags: {
                0: '<a href="#benefits">',
                1: '</a>',
              },
              replacements: {
                2: '$ 75.000',
              },
            }),
          },
          {
            text: i18n.gettext('Compra lectores de forma ilimitada.'),
          },
        ],
      },
    ],
  };
};
