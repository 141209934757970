// i18nLocalesOverride:['pt-BR']

module.exports = (i18n) => {
  return {
    title: i18n.gettext('Conviértete en revendedor en 4 pasos'),
    items: [
      {
        id: 1,
        icon: 'mobile-registro.webp',
        iconFallback: 'mobile-registro.png',
        title: i18n.gettext('Regístrate'),
        description: i18n.gettext('Ingresa tus datos para finalizar tu registro en el programa.'),
        alt: 'uma pessoa se cadastrando',
      },
      {
        id: 2,
        icon: 'mobile-compra.webp',
        iconFallback: 'mobile-compra.png',
        title: i18n.gettext('Compra'),
        description: i18n.gettext('Accede al portal y compra un kit de lectores Point.'),
        alt: 'uma pessoa comprando um kit',
      },
      {
        id: 3,
        icon: 'mobile-venda.webp',
        iconFallback: 'mobile-venda.png',
        title: i18n.gettext('Vende'),
        description: i18n.gettext(
          'Encuentra nuevos clientes y ofrece el Point más adecuado para ellos.',
        ),
        alt: 'uma pessoa vendendo uma maquininha',
      },
      {
        id: 4,
        icon: 'mobile-incentiva.webp',
        iconFallback: 'mobile-incentiva.png',
        title: i18n.gettext('Incentiva'),
        description: i18n.gettext('Cuantos más clientes vendan, más podrás recibir.'),
        alt: 'uma pessoa incentivando os vendedores',
      },
    ],
  };
};
