// i18nLocalesOverride:['es-CL']

const { formatterPrice } = require('../../../../../../services/utils/formatter');

function findDevice(devices, id) {
  if (!devices || devices.length === 0) {
    return {
      price_with_discount: 0,
      suggested_retail_price: 0,
    };
  }

  return devices?.find((device) => device?.id === id);
}

module.exports = (i18n, devices) => {
  return {
    title: i18n.gettext('Preguntas frecuentes'),
    faqs: [
      {
        title: i18n.gettext('¿En qué consiste el programa?'),
        description: i18n.gettext(
          'Como participante del programa, accedes a precios exclusivos para la compra de lectores Point Mini y Smart, para luego revenderlos al precio de mercado sugerido.',
        ),
      },
      {
        title: i18n.gettext('¿Cómo y cuándo puedo empezar a comprar?'),
        description: i18n.gettext(
          'Ya mismo puedes registrarte y empezar a comprar tus lectores a través de la plataforma creada exclusivamente para revendedores del programa.',
        ),
      },
      {
        title: i18n.gettext('¿Cuáles son los requisitos para revender lectores?'),
        description: i18n.jsx.gettext(
          'Para participar del Programa de Revendedores es necesario que cumplas con todas las condiciones previstas en los Términos y condiciones y reúnas los requisitos siguientes:{0}{0}1. Ser una persona física mayor de dieciocho (18) años de edad.{0}2. Estar domiciliado en Chile.{0}3. Ser usuario de Mercado Pago.',
          {
            tags: {
              0: '<br />',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Dónde y a quién puedo vender?'),
        description: i18n.gettext(
          'Vende a tus amistades, familiares, colegas del trabajo, a los comercios de tu barrio y a los emprendimientos de tu ciudad.',
        ),
      },
      {
        title: i18n.gettext('¿Qué características tiene cada lector?'),
        description: i18n.jsx.gettext(
          '{1}Point Mini:{2}{0}{3}Acepta todo tipo de tarjetas{4}{0}{3}Conexión bluetooth{4}{0}{3}No requiere cuenta bancaria{4}{0}{3}Depósito al instante en la cuenta de Mercado Pago{4}{0}{3}Tecnología contactless, chip y banda{4}{0}{3}1 año de garantía{4} {0}{0}{1}Point Smart:{2}{0}{3}Acepta todo tipo de tarjetas{4}{0}{3}Conexión 4G y Wifi{4}{0}{3}Depósito en 24 horas a cuenta Mercado Pago{4}{0}{3}Tecnología contactless, chip, banda, QR y billeteras virtuales{4}{0}{3}Chip Entel gratis{4}{0}{3}1 año de garantía{4}{0}',
          {
            tags: {
              0: '<br />',
              1: '<strong>',
              2: '</strong>',
              3: '<span class="bullet-list">',
              4: '</span>',
            },
          },
        ),
      },
    ],
  };
};
