// i18nLocalesOverride:['es-AR']

const provinces = [
  {
    name: 'CABA',
    code: 11,
  },
  {
    name: 'Córdoba',
    code: 351,
  },
  {
    name: 'Corrientes',
    code: 379,
  },
  {
    name: 'Formosa',
    code: 370,
  },
  {
    name: 'Buenos Aires',
    code: 221,
  },
  {
    name: 'La Rioja',
    code: 380,
  },
  {
    name: ' Mendoza',
    code: 261,
  },
  {
    name: 'Neuquén',
    code: 299,
  },
  {
    name: 'Entre Ríos',
    code: 343,
  },
  {
    name: 'Misiones',
    code: 376,
  },
  {
    name: 'Chubut',
    code: 280,
  },
  {
    name: 'Chaco',
    code: 362,
  },
  {
    name: 'Santa Cruz',
    code: 2966,
  },
  {
    name: 'Salta',
    code: 387,
  },
  {
    name: 'Catamarca',
    code: 383,
  },
  {
    name: 'San Juan',
    code: 264,
  },
  {
    name: 'San Luis',
    code: 266,
  },
  {
    name: 'Tucumán',
    code: 381,
  },
  {
    name: 'Jujuy',
    code: 388,
  },
  {
    name: 'Santa Fe',
    code: 342,
  },
  {
    name: 'La Pampa',
    code: 2954,
  },
  {
    name: 'Santiago del Estero',
    code: 385,
  },
  {
    name: 'Río Negro',
    code: 2920,
  },
  {
    name: 'Tierra del Fuego',
    code: 2901,
  },
];

module.exports = { provinces };
