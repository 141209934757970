/* eslint-disable import/order */

const React = require('react');

const { Card } = require('@andes/card');

const Error = require('./Error');
const ErrorAccountDuplicated = require('./ErrorAccountDuplicated');
const ErrorAccountBlocked = require('./ErrorAccountBlocked');
const SuccessWithAccount = require('./SuccessWithAccount');
const SuccessWithoutAccount = require('./SuccessWithoutAccount');

const { useFormContext } = require('../context/useFormContext');

const API_RESPONSE_TYPE = require('../constants/apiResponseType');

const Feedbacks = () => {
  const { apiResponseType } = useFormContext();

  const renderComponent = () => {
    switch (true) {
      case apiResponseType === API_RESPONSE_TYPE.ERROR:
        return <Error />;

      case apiResponseType === API_RESPONSE_TYPE.ERROR_ACCOUNT_DUPLICATED:
        return <ErrorAccountDuplicated />;

      case apiResponseType === API_RESPONSE_TYPE.ERROR_ACCOUNT_BLOCKED:
        return <ErrorAccountBlocked />;

      case apiResponseType === API_RESPONSE_TYPE.SUCCESS_WITH_ACCOUNT:
        return <SuccessWithAccount />;

      case apiResponseType === API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT:
      case apiResponseType === API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL:
        return <SuccessWithoutAccount />;

      default:
        return null;
    }
  };

  return <Card className="feedback-card">{renderComponent()}</Card>;
};

module.exports = Feedbacks;
