// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const Checkbox = require('@andes/checkbox');
const Typography = require('@andes/typography');

const tracking = require('../../../../services/utils/tracking');

const Tyc = ({ className, isChecked, link, onHandleChange }) => (
  <div className={`tyc-section ${className || ''}`}>
    <Checkbox checked={isChecked} onChange={() => onHandleChange()} />
    <Typography size="xs">
      Acepto los{' '}
      <a
        href={link}
        rel="noreferrer"
        target="_blank"
        onClick={() => tracking('home', 'form-terms')}
      >
        Términos y condiciones
      </a>{' '}
      del programa.
    </Typography>
  </div>
);

Tyc.defaultProps = {
  className: '',
  isChecked: false,
  link: '',
  onHandleChange: undefined,
};

Tyc.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  link: PropTypes.string,
  onHandleChange: PropTypes.func,
};

module.exports = Tyc;
