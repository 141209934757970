/* eslint-disable import/order */

const React = require('react');

const Feedbacks = require('../Feedbacks');
const LoggedUser = require('./LoggedUser');
const UnloggedUser = require('./UnloggedUser');

const { useFormContext } = require('../context/useFormContext');

const Register = () => {
  const { apiResponseType, user } = useFormContext();

  const renderComponent = () => {
    switch (true) {
      case apiResponseType !== '':
        return <Feedbacks />;
      case user?.logged:
        return <LoggedUser />;
      default:
        return <UnloggedUser />;
    }
  };

  return renderComponent();
};

module.exports = Register;
