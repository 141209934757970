// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');

const Image = require('nordic/image');
const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

const { formatterPrice } = require('../../../../services/utils/formatter');

module.exports = (siteId, devices = [], i18n) => {
  if (siteId === 'MLC') {
    const PRIMARY_COLOR = '#009EE3';

    const getProfit = (deviceId) => {
      if (!devices || devices.length === 0) {
        return 0;
      }

      const selectedDevice = devices?.find((device) => device?.id === deviceId) || {};
      const result = selectedDevice?.suggested_retail_price - selectedDevice?.price_with_discount;

      return formatterPrice(result || 0);
    };

    const getInvestiment = (deviceId) => {
      if (!devices || devices.length === 0) {
        return 0;
      }

      const selectedDevice = devices?.find((device) => device?.id === deviceId) || {};

      return formatterPrice(selectedDevice?.price_with_discount || 0);
    };

    return {
      title: i18n.gettext('Ventajas por unirte al programa'),
      imageSrc: 'discover-bg.png',
      items: [
        {
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          title: i18n.gettext('Inviertes y ganas'),
          description: i18n.gettext(
            'Gana hasta {0} al invertir {1} en Point Smart.',
            getProfit(101),
            getInvestiment(101),
          ),

          secondDescription: i18n.gettext(
            'Gana hasta {0} al invertir {1} en Point Mini.',
            getProfit(59),
            getInvestiment(59),
          ),
        },
        {
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          title: 'Manejas tus tiempos',
          description: 'Tú decides cuándo, dónde y cómo vender.',
        },
        {
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          title: i18n.gettext('Recibes capacitaciones'),
          description: i18n.gettext(
            'Te enseñamos estrategias para conseguir clientes y cerrar tus ventas.',
          ),
        },
        {
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          title: i18n.gettext('Apoyas el comercio'),
          description: i18n.gettext(
            'Ayudas a emprendedores y comercios locales, mientras creces junto a ellos.',
          ),
        },
      ],
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'Descubre más ventajas de ser representante',
      imageSrc: 'discover-bg.png',
      items: [
        {
          icon: <FeedbackPositive24 />,
          title: 'Invierte y gana',
          description: ' Con una inversión inicial de $ 745 obtén hasta $ 3,950 de ganancia.',
        },
        {
          icon: <FeedbackPositive24 />,
          title: 'Oportunidad para tus clientes',
          description:
            'Con las terminales Point tus clientes pueden cobrar con tarjeta y ser más competitivos.',
        },
        {
          icon: <FeedbackPositive24 />,
          title: 'Estás en el equipo',
          description: 'Te respalda una de las compañías que más crece en Latinoamérica.',
        },
      ],
    };
  }

  return {
    title: 'Descubrí más ventajas de ser Revendedor de Mercado Pago',
    imageSrc: 'discover-bg.png',
    items: [
      {
        icon: <Image src="money-icon.png" />,
        title: 'Empezá sin inversión',
        description: 'Accedés a créditos para empezar y seguir creciendo en el programa.',
      },
      {
        icon: <Image src="star-icon.png" />,
        title: 'No necesitás experiencia',
        description: 'Te capacitamos en todas las dimensiones que necesitás.',
      },
      {
        icon: <Image src="calendar-icon.png" />,
        title: 'Se dueño de tu tiempo',
        description: 'Vos manejás tus horarios y decidís cuándo y cómo vender.',
      },
      {
        icon: <Image src="coins-icon.png" />,
        title: 'Aprovechá la oportunidad',
        description:
          'A medida que vendés más, accedés a herramientas y niveles para potenciar tus premios con Mercado Pago.',
      },
      {
        icon: <Image src="team-icon.png" />,
        title: 'Estás en el equipo',
        description: 'Crecés junto a una de las empresas más grandes de Latinoamérica.',
      },
    ],
  };
};
