// i18nLocalesOverride:['es-AR','es-MX','es-CL']

module.exports = (siteId, i18n) => {
  if (siteId === 'MLC') {
    return {
      title: i18n.gettext('Empieza a revender con Mercado Pago'),
      subtitle: i18n.gettext('Únete al programa que te ayuda a aumentar tus ganancias.'),
      actionText: i18n.gettext('Empezar a revender'),
      address: 'Avenida Apoquindo 4800, torre 2, piso 21, Las Condes, Región Metropolitana, Chile.',
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'DE AHORA EN ADELANTE, MERCADO PAGO.',
      subtitle: 'Únete al programa que te ayudará a hacer crecer tu inversión y tus ganancias.',
      actionText: 'Quiero ser representante',
      address:
        'Av de los Insurgentes Sur 1602, Crédito Constructor, Benito Juárez, 03940 Ciudad de México, CDMX.',
    };
  }

  return {
    title: 'DE AHORA EN ADELANTE, MERCADO PAGO.',
    subtitle: 'Unite al programa que te ayudará a hacer crecer tu inversión y tus ganancias.',
    actionText: 'Quiero ser revendedor',
    address: 'Arias 3751, 7, Ciudad Autónoma de Buenos Aires (1430), Argentina',
  };
};
