// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');

const Fab = require('../../Molecules/Fab');

const Benefits = require('./components/Benefits');
const Faqs = require('./components/Faqs');
const Footer = require('./components/Footer');
const HeaderFixed = require('./components/HeaderFixed');
const Help = require('./components/Help');
const Hero = require('./components/Hero');
const Incentives = require('./components/Incentives');
const Levels = require('./components/Levels');
const PaymentMethod = require('./components/PaymentMethod');
const PointBenefits = require('./components/PointBenefits');
const Steps = require('./components/Steps');
const Tyc = require('./components/Tyc');

const LandingMLB = ({
  devices = [],
  deviceType = 'mobile',
  isWebview = false,
  prizesConfigs = [],
  traficOrigin,
  user = {},
  showShortLead,
  query,
}) => {
  const { i18n } = useI18n();

  const whatsappMessage = encodeURIComponent(
    i18n.gettext('¡Hola! Me gustaría obtener más información sobre el Programa de Revendedores.'),
  );

  return (
    <div>
      <HeaderFixed deviceType={deviceType} isReseller={user.isReseller} />
      <Hero
        deviceType={deviceType}
        isWebview={isWebview}
        query={query}
        showShortLead={showShortLead}
        traficOrigin={traficOrigin}
        user={user}
      />
      <Benefits deviceType={deviceType} />
      <Incentives deviceType={deviceType} prizesConfigs={prizesConfigs} />
      <Levels deviceType={deviceType} prizesConfigs={prizesConfigs} />
      <Steps deviceType={deviceType} />
      <PointBenefits deviceType={deviceType} />
      <PaymentMethod />
      <Faqs devices={devices} prizesConfigs={prizesConfigs} />
      <Help />
      <Footer />
      <Tyc />
      <Fab
        deviceType={deviceType}
        siteId="MLB"
        title={i18n.gettext('Resuelve tus dudas')}
        url={`https://api.whatsapp.com/send/?phone=5511966192726&text=${whatsappMessage}&app_absent=0`}
      />
    </div>
  );
};

LandingMLB.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      model: PropTypes.string,
      price: PropTypes.number,
      suggested_sale_price: PropTypes.number,
    }),
  ),
  deviceType: PropTypes.string,
  isWebview: PropTypes.bool,
  prizesConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      group_name: PropTypes.string,
      group_id: PropTypes.number,
      prizes: PropTypes.shape({
        device_activation_max_earning: PropTypes.number,
        new_level_bonus_amount: PropTypes.number,
        device_activation_earning_amount: PropTypes.number,
        tpv_percentage_earn_prize: PropTypes.number,
        tpv_max_earn_prize: PropTypes.number,
        max_prize_amount_per_month: PropTypes.number,
        min_investment: PropTypes.number,
      }),
    }),
  ),
  traficOrigin: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    isReseller: PropTypes.bool,
  }),
  showShortLead: PropTypes.bool,
  query: PropTypes.shape({
    phone: PropTypes.string,
    deep: PropTypes.string,
  }),
};

module.exports = LandingMLB;
