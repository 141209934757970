// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { useState } = React;

const { Card } = require('@andes/card');

const LoggedUserRegistration = require('../../../../Organisms/Shared/LoggedUserRegistration');

const Actions = require('./actions');
const Feedback = require('./feedback');
const UnloggedForm = require('./unloggedForm');

const Form = ({ traficOrigin, user }) => {
  const [apiResponseType, setApiResponseType] = useState('');
  const [errors, setErrors] = useState([]);

  const [name, setName] = useState(user.fullName || '');
  const [phone, setPhone] = useState(user.phone || '');
  const [email, setEmail] = useState(user.email || '');

  const encodedURL = encodeURIComponent(
    `https://www.mercadopago.cl/revendedores?${traficOrigin?.replace('&', '')}`,
  );

  const userData = {
    name,
    email,
    phone,
    logged: !!user.email,
    isReseller: user?.isReseller,
  };

  const formComponent = user.email ? (
    <LoggedUserRegistration
      callbackUrl={`https://www.mercadolibre.com/jms/mlc/lgz/logout?go=${encodedURL}`}
      email={email}
      name={user?.fullName?.split(' ')[0] || user?.fullName}
    />
  ) : (
    <UnloggedForm
      errors={errors}
      onSetEmail={setEmail}
      onSetErrors={setErrors}
      onSetName={setName}
      onSetPhone={setPhone}
    />
  );

  return (
    <Card
      className="form__card"
      data-feedback={!!apiResponseType}
      paddingsize={32}
      shadow={apiResponseType ? 'elevated' : ''}
    >
      {apiResponseType ? (
        <Feedback
          email={email}
          name={name?.split(' ')[0] || name}
          traficOrigin={traficOrigin}
          type={apiResponseType}
        />
      ) : (
        formComponent
      )}
      <Actions
        apiResponseType={apiResponseType}
        errors={errors}
        traficOrigin={traficOrigin}
        user={userData}
        onSetApiResponseType={setApiResponseType}
      />
    </Card>
  );
};

Form.propTypes = {
  traficOrigin: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    isReseller: PropTypes.bool,
  }),
};

Form.defaultProps = {
  traficOrigin: '',
  user: {
    email: '',
    fullName: '',
    phone: '',
    isReseller: false,
  },
};

module.exports = Form;
