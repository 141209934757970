// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const TextField = require('@andes/textfield');
const Typography = require('@andes/typography');

const tracking = require('../../../../../../services/utils/tracking');
const { formatterPrice } = require('../../../../../../services/utils/formatter');

const { CALC_COLS } = require('./configs');

const DevicesTable = ({ devicesConfig, devicesTotals, onSetDevicesTotals, onSetLoading }) => {
  const [devices, setDevices] = React.useState(undefined);
  const [devicesSelecteds, setDevicesSelecteds] = React.useState([]);

  const handleChangeQuantity = React.useCallback((totals) => {
    tracking('home', 'calculator-change-quantity');

    setDevicesSelecteds(totals);
  }, []);
  const sortDevices = React.useCallback(
    (devicesList, configs, devicesOrder) =>
      devicesList
        ?.sort((a, b) => {
          if (Object.keys(devicesOrder).length === 0) {
            return a.id - b.id;
          }

          return (devicesOrder[a.id] || 99) - (devicesOrder[b.id] || 99);
        })
        .map((d) => ({
          model: d.model,
          id: d.id,
          prizes: {
            usage: configs?.find((c) => c.device_id === d.id)?.usage_point_earning_amount || 0,
            activation:
              configs?.find((c) => c.device_id === d.id)?.device_activation_earning_amount ||
              configs?.find((c) => c.device_id === d.id)?.device_activation_amount ||
              0,
            profit: d.fake_price - d.price_with_discount,
            price: d.price_with_discount,
          },
        })),
    [],
  );

  const handleOriginalDevicesQty = React.useCallback(
    (devicesList, devicesListSorted) =>
      devicesList?.map((d) => {
        const deviceTotal = devicesSelecteds?.find((dev) => d.id === dev.id);
        const devicePrize = devicesListSorted?.find((dev) => d.id === dev.id).prizes;

        return {
          id: d.id,
          price: deviceTotal?.quantity * devicePrize?.price || 0,
          quantity: deviceTotal?.quantity || 0,
          usage: deviceTotal?.quantity * devicePrize?.usage || 0,
          profit: deviceTotal?.quantity * devicePrize?.profit || 0,
          activation: deviceTotal?.quantity * devicePrize?.activation || 0,
        };
      }),
    [devicesSelecteds],
  );

  React.useEffect(() => {
    const devicesSorted = sortDevices(
      devicesConfig?.devices,
      devicesConfig?.configs,
      devicesConfig?.devicesOrder,
    );
    const devTotals = handleOriginalDevicesQty(devicesConfig?.devices, devicesSorted);

    onSetDevicesTotals(devTotals);
    setDevices(devicesSorted);
    onSetLoading(!devTotals);
  }, [handleOriginalDevicesQty, sortDevices, devicesConfig, onSetDevicesTotals, onSetLoading]);

  return (
    <>
      <div className="row">
        <Typography className="cell" size="xl" />
        <Typography className="cell bold" size="xl">
          Cantidad
        </Typography>
        {CALC_COLS.map((c) => (
          <Typography key={c.title} className="cell bold" size="xl">
            {c.title}
          </Typography>
        ))}
      </div>

      {devices &&
        devices.map((d) => {
          const device = devicesTotals.find((dev) => d.id === dev.id);

          return (
            <div key={d.model} className="row">
              <Typography className="cell bold" size="xl">
                {d.model}
              </Typography>
              <TextField
                centered
                className="cell"
                defaultValue={+device.quantity}
                min={0}
                type="number"
                onChange={(evt) => {
                  device.quantity = evt.target.value;

                  Object.keys(d.prizes).forEach((p) => {
                    device[p.toString()] = +d.prizes[p.toString()] * +device.quantity;
                  });

                  onSetDevicesTotals([...devicesTotals]);
                  handleChangeQuantity(devicesTotals);
                }}
              />

              {CALC_COLS.map((c) => (
                <Typography
                  key={`${d.model}-${device.quantity}-${c.field}`}
                  className="cell"
                  color={+device.quantity === 0 ? 'disabled' : 'primary'}
                  size="xl"
                >
                  {formatterPrice(device[c.field.toString()], 'MLM')}
                </Typography>
              ))}
            </div>
          );
        })}
    </>
  );
};

DevicesTable.defaultProps = {
  devicesConfig: {},
  devicesTotals: [],
  onSetDevicesTotals: () => {},
  onSetLoading: () => {},
};

DevicesTable.propTypes = {
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
    devicesOrder: PropTypes.shape({}),
  }),
  devicesTotals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      quantity: PropTypes.number,
      price: PropTypes.number,
      usage: PropTypes.number,
      profit: PropTypes.number,
      activation: PropTypes.number,
    }),
  ),
  onSetDevicesTotals: PropTypes.func,
  onSetLoading: PropTypes.func,
};

module.exports = DevicesTable;
