// i18nLocalesOverride:['pt-BR']

module.exports = (i18n) => {
  return {
    title: i18n.gettext('GANA DINERO EXTRA VENDIENDO LECTORES'),
    marquee: 'Dinheiro na conta em até 5 dias úteis',
    image: {
      src: 'hero-renda-na-mao-mobile.webp',
      srcFallback: 'hero-renda-na-mao-mobile.png',
      alt: 'Homem de cabelo raspado, com um sorriso no rosto, de braços cruzados. Na sua frente estão as Point do Mercado Pago. Da esqueda para a direita:Point Air,Point Mini NFC 2, Point Smart e Point Pro 2.',
    },
  };
};
