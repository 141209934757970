const LANDING_PAGE_URL = {
  MLA: 'https://www.mercadopago.com.ar/revendedores',
  MLB: 'https://www.mercadopago.com.br/revendedores',
  MLC: 'https://www.mercadopago.cl/revendedores',
  MLM: 'https://www.mercadopago.com.mx/representantes',
};

const MP_CREATE_ACCOUNT_URL = {
  MLA: 'https://www.mercadopago.com.ar/hub/registration/fiscal-splitter',
  MLB: 'https://www.mercadopago.com.br/hub/registration/fiscal-splitter',
  MLC: 'https://www.mercadopago.cl/hub/registration/splitter',
  MLM: 'https://www.mercadopago.com.mx/hub/registration/splitter',
};

const MICROSITIO_URL = {
  MLA: 'https://www.mercadopago.com.ar/resellers',
  MLB: 'https://www.mercadopago.com.br/resellers',
  MLC: 'https://www.mercadopago.cl/resellers',
  MLM: 'https://www.mercadopago.com.mx/resellers',
};

const ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL = {
  MLA: `${MICROSITIO_URL.MLA}/confirmacion/true`,
  MLB: `${MICROSITIO_URL.MLB}/account-confirmation/confirm`,
  MLC: `${MICROSITIO_URL.MLC}/confirmacion/true`,
  MLM: `${MICROSITIO_URL.MLM}/confirmacion/true`,
};

const URL_PER_SITE = {
  MLA: {
    MICROSITIO: 'https://www.mercadopago.com.ar/resellers',
    ACCOUNT_CONFIRMATION: 'https://www.mercadopago.com.ar/resellers/confirmacion/true',
    MP_CREATE_ACCOUNT_URL: 'https://www.mercadopago.com.ar/hub/registration/fiscal-splitter',
  },
  MLB: {
    MICROSITIO: 'https://www.mercadopago.com.br/resellers',
    ACCOUNT_CONFIRMATION: 'https://www.mercadopago.com.br/resellers/account-confirmation/confirm',
    MP_CREATE_ACCOUNT_URL: 'https://www.mercadopago.com.br/hub/registration/fiscal-splitter',
  },
  MLC: {
    MICROSITIO: 'https://www.mercadopago.cl/resellers',
    ACCOUNT_CONFIRMATION: 'https://www.mercadopago.cl/resellers/confirmacion/true',
    MP_CREATE_ACCOUNT_URL: 'https://www.mercadopago.cl/hub/registration/splitter',
  },
  MLM: {
    MICROSITIO: 'https://www.mercadopago.com.mx/resellers',
    ACCOUNT_CONFIRMATION: 'https://www.mercadopago.com.mx/resellers/confirmacion/true',
    MP_CREATE_ACCOUNT_URL: 'https://www.mercadopago.com.mx/hub/registration/splitter',
  },
};

const MICROSITIO_DEEPLINK = {
  MLA: `https://852u.adj.st/webview?url=${encodeURIComponent(
    MICROSITIO_URL.MLA,
  )}&adj_t=1bk311fg&adj_redirect=${encodeURIComponent(MICROSITIO_URL.MLA)}`,
  MLB: `https://852u.adj.st/webview?url=${encodeURIComponent(
    MICROSITIO_URL.MLB,
  )}&adj_t=1bk311fg&adj_redirect=${encodeURIComponent(MICROSITIO_URL.MLB)}`,
  MLC: `https://852u.adj.st/webview?url=${encodeURIComponent(
    MICROSITIO_URL.MLC,
  )}&adj_t=1bk311fg&adj_redirect=${encodeURIComponent(MICROSITIO_URL.MLC)}`,
  MLM: `https://852u.adj.st/webview?url=${encodeURIComponent(
    MICROSITIO_URL.MLM,
  )}&adj_t=1bk311fg&adj_redirect=${encodeURIComponent(MICROSITIO_URL.MLM)}`,
};

const ACCOUNT_CONFIRMATION_ON_MICROSITIO_DEEPLINK = {
  MLA: `https://852u.adj.st/webview?url=${encodeURIComponent(
    ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL.MLA,
  )}&adj_t=1bk311fg&adj_redirect=${encodeURIComponent(ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL.MLA)}`,
  MLB: `https://852u.adj.st/webview?url=${encodeURIComponent(
    ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL.MLB,
  )}&adj_t=1bk311fg&adj_redirect=${encodeURIComponent(ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL.MLB)}`,
  MLC: `https://852u.adj.st/webview?url=${encodeURIComponent(
    ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL.MLC,
  )}&adj_t=1bk311fg&adj_redirect=${encodeURIComponent(ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL.MLC)}`,
  MLM: `https://852u.adj.st/webview?url=${encodeURIComponent(
    ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL.MLM,
  )}&adj_t=1bk311fg&adj_redirect=${encodeURIComponent(ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL.MLM)}`,
};

const TYC_BY_SITE = {
  MLB: 'https://www.mercadopago.com.br/ajuda/regulamento-programa-parceiros-mercado-pago_4383',
};

const INVITE_AND_WIN = {
  MLB: 'https://www.mercadopago.com.br/convide-e-ganhe',
};

module.exports = {
  ACCOUNT_CONFIRMATION_ON_MICROSITIO_DEEPLINK,
  ACCOUNT_CONFIRMATION_ON_MICROSITIO_URL,
  INVITE_AND_WIN,
  LANDING_PAGE_URL,
  MICROSITIO_DEEPLINK,
  MICROSITIO_URL,
  MP_CREATE_ACCOUNT_URL,
  TYC_BY_SITE,
  URL_PER_SITE,
};
