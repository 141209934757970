// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const Card = require('@andes/card');
const Typography = require('@andes/typography');

const { formatPriceString } = require('../../../../../../services/utils/formatter');

const { CALC_COLS, QR_ID } = require('./configs');

const Footer = ({ devicesTotals }) => {
  const getCalc = React.useCallback(() => {
    const totalDevices = devicesTotals?.reduce((res, d) => +res + +d?.quantity, 0);

    let totalClientPrizes = 0;
    let totalProfit = 0;
    let totalPrice = 0;
    let totalQr = 0;

    if (devicesTotals) {
      devicesTotals.forEach((d) => {
        totalPrice += d.price;

        CALC_COLS.forEach((calc) => {
          if (calc.type === 'prize' && calc.field === 'client') {
            totalClientPrizes += +d[calc.field.toString()];
          }
          if (calc.type === 'profit') {
            totalProfit += +d[calc.field.toString()];
          }
          if (calc.type === 'prize' && calc.field === 'qr') {
            totalQr += +d[calc.field.toString()];
          }
        });
      });
    }

    const totalGanacia = totalClientPrizes + totalProfit + totalQr;

    return {
      totalPrice,
      totalDevices,
      totalClientPrizes,
      totalProfit,
      totalGanacia,
      totalQr,
    };
  }, [devicesTotals]);

  const summary = getCalc();

  const qrSelected = devicesTotals ? devicesTotals?.find((qr) => qr.id === QR_ID) : 0;

  const labelType = summary.totalDevices === 1 ? ' lector' : ' lectores';

  const selectedQuantityLabel =
    +qrSelected?.quantity === 0 && summary.totalDevices > 0 ? labelType : '';

  return (
    <>
      <div className="row row-total">
        <Typography className="cell bold" size="xl">
          Total
        </Typography>
        <Typography className="cell bold" size="xl">
          {summary.totalDevices}
          {selectedQuantityLabel}
        </Typography>
        <Typography className="cell bold" size="xl">
          {formatPriceString(summary.totalProfit, 'MLA')}
        </Typography>
        <Typography className="cell bold" size="xl">
          {formatPriceString(summary.totalClientPrizes, 'MLA')}
        </Typography>
        <Typography className="cell bold" size="xl">
          {formatPriceString(summary.totalQr, 'MLA')}
        </Typography>
      </div>

      <Card className="calculator__total">
        <div>
          <Typography component="p">Monto a invertir</Typography>
          <Typography component="h3" type="title">
            {formatPriceString(summary.totalPrice, 'MLA')}
          </Typography>
        </div>
        <div>
          <Typography component="p">Ganancia</Typography>
          <Typography component="h3" type="title">
            {formatPriceString(summary.totalGanacia, 'MLA')}
          </Typography>
        </div>
      </Card>
    </>
  );
};

Footer.defaultProps = {
  devicesConfig: {},
  qrPrizeValue: 0,
};

Footer.propTypes = {
  devicesTotals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      quantity: PropTypes.number,
      total: PropTypes.number,
    }),
  ),
  summary: PropTypes.shape({
    totalDevices: PropTypes.number,
    totalProfit: PropTypes.number,
    totalClientPrizes: PropTypes.number,
    totalQr: PropTypes.number,
    totalPrice: PropTypes.number,
    totalGanacia: PropTypes.number,
  }),
};

module.exports = Footer;
