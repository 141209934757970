/* eslint-disable import/order */

const React = require('react');
const PropTypes = require('prop-types');

const Register = require('./Register');

const { FormProvider } = require('./context/useFormContext');

const Form = (props) => {
  return (
    <FormProvider {...props}>
      <Register />
    </FormProvider>
  );
};

Form.propTypes = {
  isWebview: PropTypes.bool,
  siteId: PropTypes.string,
  traficOrigin: PropTypes.string,
  user: PropTypes.shape({
    blocked: PropTypes.bool,
    email: PropTypes.string,
    fullName: PropTypes.string,
    isReseller: PropTypes.bool,
    phone: PropTypes.string,
  }),
};

module.exports = Form;
