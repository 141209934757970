// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const Faq = require('../../../../Organisms/Shared/FAQ');

const Faqs = ({ devices = [], prizesConfigs = [] }) => {
  return (
    <div className="faqs-wrapper">
      <div className="faqs-wrapper__content">
        <Faq configs={{ prizesConfigs, devices }} siteId="MLB" />
      </div>
    </div>
  );
};

Faqs.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      model: PropTypes.string,
      price: PropTypes.number,
      suggested_sale_price: PropTypes.number,
    }),
  ),
  prizesConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      group_name: PropTypes.string,
      group_id: PropTypes.number,
      prizes: PropTypes.shape({
        device_activation_max_earning: PropTypes.number,
        new_level_bonus_amount: PropTypes.number,
        device_activation_earning_amount: PropTypes.number,
        tpv_percentage_earn_prize: PropTypes.number,
        tpv_max_earn_prize: PropTypes.number,
        max_prize_amount_per_month: PropTypes.number,
      }),
    }),
  ),
};

module.exports = Faqs;
