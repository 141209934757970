const { validateEmail } = require('../../../../../../services/utils/validators');

const validateName = (value) => {
  const splitedTarget = value.split(' ');

  const firstWord = splitedTarget?.[0]?.length || 0;
  const secondtWord = splitedTarget?.[1]?.length || 0;

  return splitedTarget.length >= 2 && firstWord > 2 && secondtWord > 2 && value.includes(' ');
};

const validatePhone = (value) => {
  value.replace(/\D+/g, '');

  return value.length === 15;
};

module.exports = {
  validateEmail,
  validateName,
  validatePhone,
};
