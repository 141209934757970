// i18nLocalesOverride:['pt-BR','es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Typography } = require('@andes/typography');

const tracking = require('../../../../services/utils/tracking');

const Fab = ({ deviceType = 'mobile', title, url, siteId }) => {
  let scrollPos;

  const label =
    siteId === 'MLB' ? 'botão de contato por WhatsApp' : 'botón de contacto por WhatsApp';

  if (deviceType !== 'desktop' && typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        document.getElementById('fab').classList.add('has-scroll');
      }
      if (window.scrollY > 1300 && deviceType === 'mobile' && siteId === 'MLM') {
        document.getElementById('fab').classList.add('has-scroll-with-button');
      }
      if (window.scrollY < scrollPos && siteId === 'MLM') {
        document.getElementById('fab').classList.remove('has-scroll-with-button');
      }
      if (window.scrollY < scrollPos) {
        document.getElementById('fab').classList.remove('has-scroll');
      }

      scrollPos = window.scrollY;
    });
  }

  return (
    <div
      aria-label={label}
      className="fab-wrapper"
      id="fab"
      role="button"
      tabIndex={0}
      onClick={() => {
        tracking('home', 'whatsapp-fixed-button');

        window.open(url);
      }}
      onKeyDown={() => {}}
    >
      <Image preload alt="whatsapp" height={20} lazyload="off" src="whatsapp-icon.svg" width={20} />
      <Typography component="strong">{title}</Typography>
    </div>
  );
};

Fab.propTypes = {
  deviceType: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  siteId: PropTypes.string,
};

module.exports = Fab;
