const getBetterActivationPrize = (configs) => {
  let bestPrize = 0;

  configs?.forEach((prize) => {
    bestPrize =
      prize?.device_activation_earning_amount > bestPrize
        ? +prize?.device_activation_earning_amount
        : bestPrize;
  });

  return bestPrize || 0;
};

module.exports = { getBetterActivationPrize };
