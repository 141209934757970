const API_RESPONSE_TYPE = require('../constants/apiResponseType');

const getResponseType = (user) => {
  const { blocked, isReseller } = user;

  if (blocked) {
    return API_RESPONSE_TYPE.ERROR_ACCOUNT_BLOCKED;
  }

  if (isReseller) {
    return API_RESPONSE_TYPE.ERROR_ACCOUNT_DUPLICATED;
  }

  return '';
};

module.exports = getResponseType;
