// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const Benefits = require('../../Organisms/Shared/Benefits');
const ButtonFixed = require('../../Molecules/ButtonFixed');
const Discover = require('../../Molecules/Discover');
const FAQList = require('../../Organisms/Shared/FAQ');
const Footer = require('../../Organisms/Shared/Footer');
const HeaderFixed = require('../../Organisms/Shared/HeaderFixed');
const Hero = require('../../Organisms/Shared/Hero');
const Points = require('../../Organisms/Shared/Points');
const PointsInfo = require('../../Organisms/Shared/PointsInfo');
const Timeline = require('../../Organisms/Shared/Timeline');
const Video = require('../../Organisms/Shared/Video');

const Calculator = require('./components/Calculator');
const Levels = require('./components/Levels');
const Register = require('./components/Register');

const LandingMLA = ({ data, devicesConfig, deviceType, groupConfigs, traficOrigin, userData }) => {
  const orderedDevices = data?.devices?.sort(
    (a, b) => a?.price_with_discount - b?.price_with_discount,
  );

  const userLogged = userData?.email && userData?.email !== '';

  return (
    <div>
      <HeaderFixed isReseller={userData?.isReseller} siteId="MLA" />
      <Hero deviceType={deviceType} siteId="MLA" />
      <Register deviceType={deviceType} traficOrigin={traficOrigin} user={userData} />
      <Timeline deviceType={deviceType} siteId="MLA" />
      <Discover siteId="MLA" />
      <Points deviceType={deviceType} devices={orderedDevices} siteId="MLA" />
      <Benefits
        deviceType={deviceType}
        devicesConfig={devicesConfig}
        groupConfigs={groupConfigs}
        siteId="MLA"
      />
      <Calculator devicesConfig={devicesConfig} qrPrizeValue={groupConfigs?.qr_earned_by_seller} />
      <Levels deviceType={deviceType} />
      <Video siteId="MLA" />
      <PointsInfo siteId="MLA" />
      <FAQList devices={orderedDevices} siteId="MLA" />
      <Footer deviceType={deviceType} siteId="MLA" />
      <ButtonFixed deviceType={deviceType} isLogged={userLogged} siteId="MLM" />
    </div>
  );
};

LandingMLA.defaultProps = {
  traficOrigin: '',
  deviceType: '',
  userData: {},
  data: {},
  devicesConfig: {},
  groupConfigs: {},
};

LandingMLA.propTypes = {
  deviceType: PropTypes.string,
  traficOrigin: PropTypes.string,
  userData: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    isReseller: PropTypes.bool,
  }),
  data: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.any),
    groups: PropTypes.arrayOf(PropTypes.any),
  }),
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  groupConfigs: PropTypes.shape({
    qr_earned_by_seller: PropTypes.number,
  }),
};

module.exports = LandingMLA;
