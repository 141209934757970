// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { Typography } = require('@andes/typography');

const ItemDetails = ({
  title,
  description,
  secondDescription,
  icon,
  className,
  type = 'discovery',
  bullets,
  hasBullet,
}) => {
  const classType = {
    discovery: 'discover-item',
    benefit: 'benefit-item',
    level: 'level-item',
    point: 'point-item',
  };

  return (
    <div className={`${classType[type || 'discovery']} ${className || ''}`}>
      {icon && <div>{icon}</div>}
      <div>
        {title && (
          <Typography className="bold" size="xs" type="title">
            {title}
          </Typography>
        )}
        {description && (
          <Typography className={type === 'level' && hasBullet ? 'bullet' : ''}>
            {type === 'level' && hasBullet && <span />}
            {description}
          </Typography>
        )}
        {secondDescription && (
          <Typography className="secondary-description">{secondDescription}</Typography>
        )}
        {bullets?.length > 0 &&
          bullets.map((item) => (
            <>
              <Typography key={item.text} className="bullet-item">
                {item.text}
                {item?.link && <a href={item.link.url}>{item.link.text}</a>}
                {item?.secondaryText && item.secondaryText}
              </Typography>
              {}
            </>
          ))}
      </div>
    </div>
  );
};

ItemDetails.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  secondDescription: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
  hasBullet: PropTypes.bool,
  type: PropTypes.oneOf(['discovery', 'benefit', 'level', 'point']),
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
  ),
};

module.exports = ItemDetails;
