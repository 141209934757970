// i18nLocalesOverride:['es-MX']

const React = require('react');

const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

module.exports = {
  title: 'Tarjeta de débito gratis con tus compras',
  imageSrc: 'point-info-mobile.png',
  items: [
    {
      icon: <FeedbackPositive24 />,
      title: 'Más soluciones, más ventas',
      description:
        'Ahora puedes ofrecer la tarjeta débito a tus clientes y así aumentar tus ventas.',
    },
    {
      icon: <FeedbackPositive24 />,
      title: 'Tarjeta de débito sin datos impresos',
      description: 'Tus clientes podrán retirar dinero o pagar en cualquier comercio.',
    },
  ],
};
