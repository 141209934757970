const getRestClient = require('./restclient');

const restclient = getRestClient('/api/revendedores');

const getDevices = () => restclient.get('/devices');

const getDevicesConfigs = (groupId) => restclient.get(`/devices/group/${groupId}`);

module.exports = {
  getDevices,
  getDevicesConfigs,
};
