// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { Typography } = require('@andes/typography');
const { CarouselFree } = require('@andes/carousel-free');
const { CarouselSnapped } = require('@andes/carousel-snapped');

const { CarouselSnappedSlide } = CarouselSnapped;
const { CarouselFreeSlide } = CarouselFree;

const ItemDetails = require('../../../Molecules/ItemDetails');

const configs = require('./config');

const Benefits = ({ siteId, deviceType, groupConfigs, devicesConfig }) => {
  const { title, prizes } = configs(siteId, devicesConfig, groupConfigs);

  const pagination = siteId === 'MLM' ? 3 : 4;

  return (
    <div className={`benefits-wrapper ${siteId?.toLowerCase()}`}>
      <Typography component="h2" size="l" type="title">
        {title}
      </Typography>
      <div className="benefits">
        {deviceType === 'mobile' ? (
          <CarouselFree>
            {prizes.map((item) => (
              <CarouselFreeSlide key={item.id}>
                <ItemDetails key={item.id} {...item} />
              </CarouselFreeSlide>
            ))}
          </CarouselFree>
        ) : (
          <CarouselSnapped
            arrows
            pagination={prizes.length > pagination}
            slidesPerView={prizes.length >= 4 ? 4 : prizes.length}
          >
            {prizes.map((item, idx) => (
              <CarouselSnappedSlide key={item.id} index={idx}>
                <ItemDetails key={item.id} {...item} />
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        )}
      </div>
    </div>
  );
};

Benefits.defaultProps = {
  deviceType: 'mobile',
  siteId: 'MLA',
  devicesConfig: {},
  groupConfigs: {},
};

Benefits.propTypes = {
  deviceType: PropTypes.string,
  siteId: PropTypes.string,
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  groupConfigs: PropTypes.shape({}),
};

module.exports = Benefits;
