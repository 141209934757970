// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const Typography = require('@andes/typography');
const CarouselSnapped = require('@andes/carousel-snapped');

const { CarouselSnappedSlide } = CarouselSnapped;

const { useI18n } = require('nordic/i18n');

const CardDivider = require('../../../../Atoms/CardDivider');
const ItemDetails = require('../../../../Molecules/ItemDetails');

const configs = require('./config');

const Benefits = ({ groupConfigs, deviceType, devicesConfig }) => {
  const { i18n } = useI18n();
  const { prizes, title } = configs(groupConfigs, devicesConfig, i18n);

  return (
    <div className="benefits-wrapper" id="benefits">
      <CardDivider />
      <Typography component="h2" size="m" type="title">
        {title}
      </Typography>

      <div className="benefits">
        {deviceType === 'mobile' ? (
          <CarouselSnapped arrows={false}>
            {prizes.map((item) => (
              <CarouselSnappedSlide key={item.id}>
                <ItemDetails key={item.id} {...item} />
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        ) : (
          prizes.map((item) => <ItemDetails key={item.id} {...item} />)
        )}
      </div>
    </div>
  );
};

Benefits.defaultProps = {
  deviceType: 'mobile',
  groupConfigs: {
    frequent_purchases_device_quantity: 0,
    frequent_purchases_earning_amount: 0,
  },
  devicesConfig: [],
};

Benefits.propTypes = {
  deviceType: PropTypes.string,
  groupConfigs: PropTypes.shape({
    configs: PropTypes.shape({
      frequent_purchases_device_quantity: PropTypes.number,
      frequent_purchases_earning_amount: PropTypes.number,
    }),
  }),
  devicesConfig: PropTypes.arrayOf({
    configs: PropTypes.arrayOf({
      device_activation_earning_amount: PropTypes.number,
    }),
  }),
};

module.exports = Benefits;
