// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const ButtonFixed = require('../../Molecules/ButtonFixed');
const Discover = require('../../Molecules/Discover');
const FAQList = require('../../Organisms/Shared/FAQ');
const Footer = require('../../Organisms/Shared/Footer');
const HeaderFixed = require('../../Organisms/Shared/HeaderFixed');
const Hero = require('../../Organisms/Shared/Hero');
const Points = require('../../Organisms/Shared/Points');
const PointsInfo = require('../../Organisms/Shared/PointsInfo');
const Timeline = require('../../Organisms/Shared/Timeline');
const Video = require('../../Organisms/Shared/Video');

const Benefits = require('./components/Benefits');
const Calculator = require('./components/Calculator');
const Levels = require('./components/Levels');
const Register = require('./components/Register');

const LandingMLC = ({
  traficOrigin,
  deviceType,
  groupConfigs,
  userData,
  data,
  devicesConfig,
  levelConfigs,
}) => {
  const userLogged = userData?.email && userData?.email !== '';

  const sortedDevices =
    data?.devices?.sort((a, b) => a.price_with_discount - b.price_with_discount) || [];

  return (
    <div>
      <HeaderFixed isReseller={userData?.isReseller} siteId="MLC" />
      <Hero deviceType={deviceType} siteId="MLC" />
      <Register deviceType={deviceType} traficOrigin={traficOrigin} user={userData} />
      <Timeline deviceType={deviceType} siteId="MLC" />
      <Discover devices={data?.devices || []} siteId="MLC" />
      <Points deviceType={deviceType} devices={sortedDevices} siteId="MLC" />
      <Benefits deviceType={deviceType} devicesConfig={devicesConfig} groupConfigs={groupConfigs} />
      <Levels devices={data?.devices || []} levelConfigs={levelConfigs} siteId="MLC" />
      <Video siteId="MLC" />
      <PointsInfo siteId="MLC" />
      <FAQList devices={data?.devices || []} siteId="MLC" />
      <Footer deviceType={deviceType} siteId="MLC" />
      <ButtonFixed deviceType={deviceType} isLogged={userLogged} siteId="MLC" />
    </div>
  );
};

LandingMLC.defaultProps = {
  address: '',
  traficOrigin: '',
  deviceType: '',
  userData: {},
  data: {},
  devicesConfig: {},
};

LandingMLC.propTypes = {
  deviceType: PropTypes.string,
  traficOrigin: PropTypes.string,
  userData: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    isReseller: PropTypes.bool,
  }),
  data: PropTypes.shape({
    devices: PropTypes.arrayOf(),
    groups: PropTypes.arrayOf(),
  }),
  levelConfigs: PropTypes.shape({
    emprendedor: PropTypes.shape({
      frequent_purchases_earning_amount: PropTypes.number,
      frequent_purchases_device_quantity: PropTypes.number,
    }),
    profesional: PropTypes.shape({
      frequent_purchases_earning_amount: PropTypes.number,
      frequent_purchases_device_quantity: PropTypes.number,
    }),
  }),
  groupConfigs: PropTypes.shape({}),
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

module.exports = LandingMLC;
