// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { Form } = require('@andes/form');
const { Button } = require('@andes/button');

const FORM_STATUS = require('../../../../../../services/constants/formStatus');
const Recaptcha = require('../../../../Molecules/Recaptcha');
const tracking = require('../../../../../../services/utils/tracking');
const Tyc = require('../../../../Molecules/Tyc');

const useActions = require('./hooks/useActions');

const Actions = ({ apiResponseType, errors, onSetApiResponseType, user, traficOrigin }) => {
  const {
    buttons,
    counter,
    isDisabledButton,
    register,
    setDisabledButton,
    setTYC,
    showRecaptchaError,
    tyc,
  } = useActions({ apiResponseType, errors, onSetApiResponseType, user, traficOrigin });

  return (
    <div className="form__footer">
      {!user.isReseller && !apiResponseType && (
        <Tyc
          isChecked={tyc}
          link="https://www.mercadopago.com.mx/ayuda/5251"
          onHandleChange={() => {
            setTYC(!tyc);
            tracking('home', 'click-on-terms-and-conditions');
          }}
        />
      )}
      <Form
        onSubmit={(e) => {
          setDisabledButton(true);
          register(e);
        }}
      >
        {!user.isReseller && !apiResponseType && (
          <Recaptcha countryId="MX" errors={showRecaptchaError ? ['Completá el reCAPTCHA.'] : []} />
        )}
        {buttons?.primary?.text && (
          <Button
            fullWidth
            disabled={
              [FORM_STATUS.SUCCESS, FORM_STATUS.RESEND_EMAIL].includes(apiResponseType)
                ? counter > 0
                : isDisabledButton
            }
            modifier="filled"
            type={buttons.primary?.onClick ? 'button' : 'submit'}
            onClick={() => {
              tracking('home', buttons.primary.tracking);

              if (buttons.primary?.onClick) buttons.primary.onClick();
            }}
          >
            {buttons?.primary?.text(counter)}
          </Button>
        )}
        {buttons?.secondary?.text && (
          <Button
            fullWidth
            className="mt-8"
            hierarchy={buttons?.secondary?.hierarchy || 'transparent'}
            onClick={() => {
              tracking('home', buttons.secondary.tracking);

              buttons.secondary.onClick();
            }}
          >
            {buttons.secondary?.text()}
          </Button>
        )}
      </Form>
    </div>
  );
};

Actions.defaultProps = {
  errors: [],
  onSetApiResponseType: () => {},
  user: {
    email: '',
    logged: false,
    name: '',
    phone: '',
    isReseller: false,
  },
  traficOrigin: '',
};

Actions.propTypes = {
  apiResponseType: PropTypes.oneOf([
    FORM_STATUS.ERROR,
    FORM_STATUS.IS_RESELLER,
    FORM_STATUS.PENDING,
    FORM_STATUS.SUCCESS,
    '',
  ]),
  errors: PropTypes.arrayOf(PropTypes.string),
  onSetApiResponseType: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string,
    logged: PropTypes.bool,
    name: PropTypes.string,
    phone: PropTypes.string,
    isReseller: PropTypes.bool,
  }),
  traficOrigin: PropTypes.string,
};

module.exports = Actions;
