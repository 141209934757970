// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { useEffect } = React;

const { Image } = require('nordic/image');

const CardDivider = require('../../../../Atoms/CardDivider');

const Form = require('./form');
const Header = require('./header');

const Register = ({ traficOrigin, user, deviceType }) => {
  useEffect(() => {
    const formParam = /utm_position=form/g;
    const findFormParam = traficOrigin?.match(formParam);

    if (findFormParam?.length > 0) {
      window.scrollTo(0, deviceType === 'mobile' ? 750 : 550);
    }
  }, [deviceType, traficOrigin]);

  return (
    <div className="form__wrapper">
      <CardDivider />
      <Header />
      <div className="form__content">
        <div className="form__image">
          <Image preload height={327} lazyload="off" src="form-bg.png" width={460} />
        </div>
        <Form traficOrigin={traficOrigin} user={user} />
      </div>
    </div>
  );
};

Register.defaultProps = {
  deviceType: 'mobile',
  user: {
    email: '',
    phone: '',
    fullName: '',
  },
  traficOrigin: '',
};

Register.propTypes = {
  deviceType: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
  }),
  traficOrigin: PropTypes.string,
};

module.exports = Register;
