const getRestClient = require('./restclient');

const restclient = getRestClient('/api/revendedores');

const getResellerInfo = (nickname) =>
  restclient.post(`/user?nickname=${nickname}`).then((resp) => resp.data);

const searchUser = (email) =>
  restclient
    .post('/user/search', {
      data: {
        email,
      },
      noLoading: true,
    })
    .then((resp) => resp.data);

const getProductLink = (id, nickname) =>
  restclient.post(`/user/product-link/${id}?nickname=${nickname}`).then((resp) => resp.data);

module.exports = {
  getResellerInfo,
  getProductLink,
  searchUser,
};
