// i18nLocalesOverride:['es-MX']

const React = require('react');

const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

module.exports = {
  title: 'Crece con los niveles del programa y accede a nuevos beneficios',
  imageSrc: 'levels.png',
  items: [
    {
      icon: <FeedbackPositive24 color="#009EE3" />,
      title: 'Aficionado',
      description: 'Gana hasta $ 890 por cada terminal que vendas.',
    },
    {
      icon: <FeedbackPositive24 color="#009EE3" />,
      title: 'Profesional',
      description:
        'Gana hasta $ 1,240 por cada terminal que vendas. Accede a más promociones y beneficios especiales.',
    },
    {
      icon: <FeedbackPositive24 color="#009EE3" />,
      title: 'Experto',
      description: 'Gana hasta $ 1,440 por cada terminal que vendas.',
    },
  ],
};
