// i18nLocalesOverride:['pt-BR']

module.exports = (deviceType, i18n) => {
  return {
    title: i18n.gettext('El Point es la opción ideal para revender'),
    image: {
      alt: 'Diferentes modelos de maquininhas do Mercado Pago. Da esquerda para direita: Point Mini NFC 1, Point Tap, Point Pro 2 e Point Smart.',
      src:
        deviceType === 'mobile'
          ? 'modelos-de-maquininhas-point-mobile.webp'
          : 'modelos-de-maquininhas-point.webp',
      srcFallback:
        deviceType === 'mobile'
          ? 'modelos-de-maquininhas-point-mobile.png'
          : 'modelos-de-maquininhas-point.png',
      title: 'modelos-de-maquininhas-point',
    },
    items: [
      {
        title: i18n.gettext('Garantía de 3 años.'),
      },
      {
        title: i18n.gettext('Tasas especiales según la facturación.'),
      },
      {
        title: i18n.gettext('Sin alquiler ni tasa de adhesión.'),
      },
      {
        title: i18n.gettext('Cuenta digital gratuita.'),
      },
    ],
  };
};
