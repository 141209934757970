// i18nLocalesOverride:['es-AR']

module.exports = {
  QR_ID: 41,
  CALC_COLS: [
    {
      title: 'Márgen de venta',
      field: 'profit',
      type: 'profit',
    },
    {
      title: 'Premio nuevo cliente Point',
      field: 'client',
      type: 'prize',
    },
    {
      title: 'Premio nuevo cliente QR',
      field: 'qr',
      type: 'prize',
    },
  ],
};
