// i18nLocalesOverride:['es-AR','es-MX','es-CL']

/* eslint-disable jsx-a11y/control-has-associated-label */
const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { Button } = require('@andes/button');
const { Typography } = require('@andes/typography');
const ChevronUp16 = require('@andes/icons/ChevronUp16');

const tracking = require('../../../../../services/utils/tracking');

const configs = require('./config');

const FooterComponent = ({ deviceType, siteId = 'MLA' }) => {
  const { i18n } = useI18n();
  const config = configs(siteId, i18n);

  const handleScroll = (position) => window?.scrollTo(0, position);

  const svgIcon = (
    <svg
      fill="none"
      height="41"
      viewBox="0 0 154 41"
      width="154"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.9525 0C42.7661 0 53.0044 40.9851 0.0917969 40.9851H153.908C104.071 40.9851 113.139 0 77.9525 0Z"
        fill="#068EE4"
      />
    </svg>
  );

  return (
    <div className={`footer ${siteId?.toLowerCase()}`}>
      <div
        className="go-to-top"
        role="button"
        tabIndex={-1}
        onClick={() => {
          tracking('home', 'footer-scroll-to-top');

          handleScroll(0);
        }}
        onKeyDown={() => {}}
      >
        <ChevronUp16 className="chevron" color="#ffffff" />
        {svgIcon}
      </div>
      <div className="footer-banner">
        <Typography component="h3" type="title">
          {config.title}
        </Typography>
        <Typography size="xs" type="title">
          {config.subtitle}
        </Typography>
        <Button
          hierarchy="transparent"
          onClick={() => {
            tracking('click', { page_resource: 'landing/footer-button' });

            handleScroll(deviceType !== 'mobile' ? 550 : 750);
          }}
        >
          {config.actionText}
        </Button>
      </div>
      {config?.address && (
        <div className="nav-footer-address">
          <p className="text">
            <small>{config?.address}</small>
          </p>
        </div>
      )}
    </div>
  );
};

FooterComponent.propTypes = {
  deviceType: PropTypes.string,
  siteId: PropTypes.string,
};

module.exports = FooterComponent;
