/* eslint-disable import/order */

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/useFormContext');

const { Checkbox } = require('@andes/checkbox');
const { Pill } = require('@andes/badge');
const { Text } = require('@andes/typography');

const { TYC_BY_SITE } = require('../../../../../../../services/constants/externalUrls');

const Tyc = ({ isChecked = false, onHandleChange = () => {} }) => {
  const { i18n } = useI18n();

  const { showErrors, errors, siteId } = useFormContext();

  const hasError = showErrors && errors?.includes('TYC');

  return (
    <div className="tyc-wrapper" data-error={hasError}>
      <div className="tyc-wrapper__checkbox">
        <Checkbox
          checked={isChecked}
          srLabel="Aceito os termos e condições"
          onChange={() => onHandleChange()}
        />
        <Text size="s">
          {i18n.jsx.gettext('Acepto los {0}Términos y condiciones.{1}', {
            tags: {
              0: `<a href="${TYC_BY_SITE[siteId]}" rel="noreferrer" target="_blank">`,
              1: '</a>',
            },
          })}
        </Text>
      </div>
      {!isChecked && hasError && (
        <div className="tyc-wrapper__error">
          <Pill color="red" contentType="icon" size="small" />
          <Text color="negative" component="p" size="xs">
            {i18n.gettext('Es necesario aceptarlos para avanzar.')}
          </Text>
        </div>
      )}
    </div>
  );
};

Tyc.propTypes = {
  isChecked: PropTypes.bool,
  onHandleChange: PropTypes.func,
};

module.exports = Tyc;
