// i18nLocalesOverride:['es-AR','es-MX','es-CL']

module.exports = (siteId, isLogged) => {
  if (siteId === 'MLM') {
    return { title: isLogged ? 'Quiero ser representante' : 'Registrar datos' };
  }

  return {
    title: isLogged ? 'Quiero ser revendedor' : 'Registrar datos',
  };
};
