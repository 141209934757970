const getRestClient = require('./restclient');

const restclient = getRestClient('/api/revendedores');

const saveLeads = (body) =>
  restclient
    .post('/selfpurchase/leads', {
      data: body,
    })
    .then((resp) => resp);

module.exports = {
  saveLeads,
};
