// i18nLocalesOverride:['es-AR']

const React = require('react');

const Image = require('nordic/image');

module.exports = {
  title: 'Crecé con los niveles del programa y accedé a nuevos beneficios',
  items: [
    {
      type: 'level',
      icon: <Image alt="Aprendiz" src="aprendiz.png" />,
      title: 'Aprendiz',
      description:
        'Empezá tu camino como revendedor y ganá hasta $ 2.500 por margen de reventa de cada Point y hasta $ 1.800 más, en premios.',
    },
    {
      type: 'level',
      icon: <Image alt="Experto" src="experto.png" />,
      title: 'Emprendedor',
      description:
        'Podés ganar más de $ 50.000* en premios más el margen de la reventa de cada Point.',
    },
    {
      type: 'level',
      icon: <Image alt="Reclutador" src="reclutador.png" />,
      title: 'Experto',
      description:
        'Podés ganar más de $ 100.000* en premios más el margen de la reventa de cada Point.',
    },
    {
      type: 'level',
      icon: <Image alt="Líder" src="lider.png" />,
      title: 'Preconsultor',
      description:
        'Podés ganar más de $ 150.000* en premios más el rendimiento de tus revendedores, además del margen de la reventa de cada Point.',
    },
    {
      type: 'level',
      icon: <Image alt="Consultor Certificado" src="consultor.png" />,
      title: 'Consultor Certificado',
      description:
        'Podés ganar más de $ 250.000* en premios, también como referente de Mercado Pago y por la atención post-venta de comercios, además por el margen de la reventa de cada Point.',
    },
  ],
  observation: '*Esta proyección de ganancia es mensual.',
};
