// i18nLocalesOverride:['es-MX']

module.exports = {
  CALC_COLS: [
    {
      title: 'Premio de uso',
      field: 'usage',
      type: 'prize',
    },
    {
      title: 'Premio de activación',
      field: 'activation',
      type: 'prize',
    },
    {
      title: 'Ganancia de venta',
      field: 'profit',
      type: 'profit',
    },
  ],
};
