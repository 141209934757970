const React = require('react');
const PropTypes = require('prop-types');

const { useEffect, useState } = React;

const { AndesProvider } = require('@andes/context');
const { useI18n } = require('nordic/i18n');
const Page = require('nordic/page');

const { addAccessCounter } = require('../../../services/kvs');
const countryLanguage = require('../../../services/utils/countryLanguage');
const Commons = require('../../components/Commons');
const LandingMLA = require('../../components/Templates/LandingMLA');
const LandingMLB = require('../../components/Templates/LandingMLB');
const LandingMLC = require('../../components/Templates/LandingMLC');
const LandingMLM = require('../../components/Templates/LandingMLM');

const View = (props) => {
  const {
    csrfToken,
    data,
    devices,
    devicesConfig,
    deviceType = 'mobile',
    experiments,
    groupConfigs,
    isWebview,
    levelConfigs,
    prizesConfigs,
    query,
    siteId,
    traficOrigin,
    userData,
  } = props;
  const [showShortLead, setShowShortLead] = useState(false);

  const { i18n } = useI18n();

  const locale = countryLanguage(siteId) || i18n.locale;

  useEffect(() => {
    if (siteId === 'MLA' || siteId === 'MLM') {
      setTimeout(() => {
        addAccessCounter();
      }, 0);
    }
  }, []);

  useEffect(() => {
    if (experiments === 'do-redirect' || window.location.search.includes('redirect=true')) {
      const generateDeeplinkUrl = () => {
        const { href, search } = window.location;
        const cleanedSearch = search
          .replace(/[?&]alreadyRedirected=true/g, '')
          .replace(/[?&]redirect=true/g, '')
          .replace(/[?&]deep=true/g, '');

        const queryParam = new URLSearchParams(cleanedSearch);

        queryParam.set('alreadyRedirected', 'true');

        const universalUrl = `mercadopago://webview?url=${encodeURIComponent(
          `${href.split('?')[0]}?${queryParam.toString()}`,
        )}`;

        return universalUrl;
      };

      const userAgentAndroid = navigator.userAgent.toLowerCase().includes('android');
      const userAgentIos = navigator.userAgent.toLowerCase().includes('iphone');

      if (
        (userAgentAndroid || userAgentIos) &&
        (experiments === 'do-redirect' || window.location.search.includes('redirect=true')) &&
        !window.location.search.includes('alreadyRedirected=true')
      ) {
        window.location.href = generateDeeplinkUrl();
      }
    }
    setShowShortLead(query.deep);
    window.addEventListener('expand-lead', () => {
      setShowShortLead(false);
    });
  }, []);

  const renderLandingComponent = () => {
    switch (siteId) {
      case 'MLM':
        return (
          <LandingMLM
            data={data}
            deviceType={deviceType}
            devicesConfig={devicesConfig}
            traficOrigin={traficOrigin}
            userData={userData}
          />
        );
      case 'MLC':
        return (
          <LandingMLC
            data={data}
            deviceType={deviceType}
            devicesConfig={devicesConfig}
            groupConfigs={groupConfigs}
            levelConfigs={levelConfigs}
            traficOrigin={traficOrigin}
            userData={userData}
          />
        );
      case 'MLB':
        return (
          <LandingMLB
            deviceType={deviceType}
            devices={devices}
            isWebview={isWebview}
            prizesConfigs={prizesConfigs}
            query={query}
            showShortLead={showShortLead}
            traficOrigin={traficOrigin}
            user={userData}
          />
        );
      case 'MLA':
      default:
        return (
          <LandingMLA
            data={data}
            deviceType={deviceType}
            devicesConfig={devicesConfig}
            groupConfigs={groupConfigs}
            traficOrigin={traficOrigin}
            userData={userData}
          />
        );
    }
  };

  return (
    <AndesProvider csrfToken={csrfToken} locale={locale}>
      <Page className={siteId?.toLowerCase()} name="landing" state={props}>
        <Commons {...props} name="home" />
        {renderLandingComponent()}
      </Page>
    </AndesProvider>
  );
};

View.propTypes = {
  csrfToken: PropTypes.string,
  data: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      model: PropTypes.string,
      price: PropTypes.number,
      suggested_sale_price: PropTypes.number,
    }),
  ),
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  deviceType: PropTypes.string,
  groupConfigs: PropTypes.shape({
    frequent_purchases_earning_amount: PropTypes.number,
    frequent_purchases_device_quantity: PropTypes.number,
  }),
  isWebview: PropTypes.bool,
  levelConfigs: PropTypes.shape({
    emprendedor: PropTypes.shape({
      frequent_purchases_earning_amount: PropTypes.number,
      frequent_purchases_device_quantity: PropTypes.number,
    }),
    profesional: PropTypes.shape({
      frequent_purchases_earning_amount: PropTypes.number,
      frequent_purchases_device_quantity: PropTypes.number,
    }),
  }),
  prizesConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      group_name: PropTypes.string,
      group_id: PropTypes.number,
      prizes: PropTypes.shape({
        device_activation_max_earning: PropTypes.number,
        new_level_bonus_amount: PropTypes.number,
        device_activation_earning_amount: PropTypes.number,
        tpv_percentage_earn_prize: PropTypes.number,
        tpv_max_earn_prize: PropTypes.number,
        max_prize_amount_per_month: PropTypes.number,
        min_investment: PropTypes.number,
      }),
    }),
  ),
  siteId: PropTypes.string.isRequired,
  traficOrigin: PropTypes.string,
  translations: PropTypes.shape({}),
  userData: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
  }),
  showShortLead: PropTypes.string,
  query: PropTypes.shape({
    phone: PropTypes.string,
    deep: PropTypes.string,
  }),
  experiments: PropTypes.string,
};

module.exports = View;
