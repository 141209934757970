/* eslint-disable import/order */

const React = require('react');

const { useState } = React;

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/useFormContext');

const { Button } = require('@andes/button');
const { Text, Title } = require('@andes/typography');

const tracking = require('../../../../../../../services/utils/tracking');

const API_RESPONSE_TYPE = require('../../constants/apiResponseType');

const SuccessWithoutAccount = () => {
  const { user, onResendEmail, counter, apiResponseType } = useFormContext();

  const [isEmailResent, setIsEmailResent] = useState(
    apiResponseType === API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL,
  );

  const { email } = user;

  const { i18n } = useI18n();

  const resendEmail = () => {
    tracking('home', 'form-register-success-create-account');

    onResendEmail();

    setIsEmailResent(true);
  };

  const registerWithAnotherAccount = () => {
    tracking('home', 'form-register-success-register-with-other-account');

    window.location.reload();
  };

  return (
    <div className="success-without-account">
      <Title component="h6" size="s">
        {i18n.gettext('Valida tu e-mail')}
      </Title>
      <Text component="p">
        {isEmailResent
          ? i18n.gettext(
              'Reenviamos la validación a {0}. Verifica tu bandeja de entrada y los correos no deseados.',
              email,
            )
          : i18n.gettext('Accede al e-mail que enviamos a {0} y continúa con tu registro.', email)}
      </Text>
      <div className="success-without-account__actions">
        <Button fullWidth disabled={counter > 0} onClick={resendEmail}>
          {counter > 0
            ? i18n.gettext('Reenviar e-mail en {0}', `00:${counter}`)
            : i18n.gettext('Reenviar e-mail')}
        </Button>
        <Button fullWidth hierarchy="transparent" onClick={registerWithAnotherAccount}>
          {i18n.gettext('Registrar otro e-mail')}
        </Button>
      </div>
    </div>
  );
};

module.exports = SuccessWithoutAccount;
