// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { useCallback, useEffect, useState } = React;

const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const { Typography } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');

const { getExternalURLs } = require('../../../../../../services/utils/navigation');
const tracking = require('../../../../../../services/utils/tracking');
const FORM_STATUS = require('../../../../../../services/constants/formStatus');

const Feedback = ({ email, name, traficOrigin, type }) => {
  const { i18n } = useI18n();

  const COUNTER_TIME = 10;
  const [counter, setCounter] = useState(COUNTER_TIME);

  const showCounter = type === FORM_STATUS.SUCCESS_HAS_ACCOUNT_AND_NOT_LOGGED;
  const externalUrls = getExternalURLs('MLM', traficOrigin);

  const validateMessage = () => {
    switch (type) {
      case FORM_STATUS.SUCCESS_HAS_ACCOUNT_AND_NOT_LOGGED:
        return {
          title: i18n.gettext('Accede a tu cuenta y continúa el registro'),
          description: i18n.gettext(
            'En {0}, te llevaremos a iniciar sesión en Mercado Pago.',
            `00:${counter}`,
          ),
        };

      case FORM_STATUS.RESEND_EMAIL:
        return {
          title: i18n.gettext('Valida tu e-mail'),
          description: i18n.gettext(
            'Reenviamos la validación a {0}. Revisa tu bandeja de entrada y los correos no deseados.',
            email,
          ),
        };

      case FORM_STATUS.IS_RESELLER:
        return {
          title: i18n.gettext('¡Hola, {0}!', name),
          description: i18n.gettext(
            'El e-mail {0} ya está registrado. Accede a tu portal y aprovecha tus beneficios.',
            email,
          ),
        };

      default:
        return {
          title: i18n.gettext('Valida tu e-mail'),
          description: i18n.gettext(
            'Revisa el e-mail que enviamos a {0} y continúa con tu registro',
            email,
          ),
        };
    }
  };

  const handleRedirectToConfirmationPage = useCallback(() => {
    tracking(
      'go_to_portal',
      'form-register_user_not_logged_but_with_account_automatic_redirect',
      true,
    );

    window.open(externalUrls.ACCOUNT_CONFIRMATION, '_self');
  }, [externalUrls]);

  useEffect(() => {
    if (!showCounter) {
      return () => {};
    }

    const timer = setInterval(
      () =>
        setCounter((state) => {
          const newState = state - 1;

          return String(newState).padStart(2, '0');
        }),
      1000,
    );

    if (counter <= 0) {
      clearInterval(timer);

      return handleRedirectToConfirmationPage();
    }

    return () => clearInterval(timer);
  }, [counter, handleRedirectToConfirmationPage, showCounter, type]);

  const message = validateMessage();

  return (
    <div className={showCounter ? 'mb-16' : 'mb-32'}>
      <Typography className="block text-center font-bold" type="title">
        {message.title}
      </Typography>
      <Typography
        className={showCounter ? 'mt-32 text-start flex items-center gap-12' : 'mt-24 text-center'}
        component="p"
      >
        {showCounter && (
          <ProgressIndicatorCircular id="logIn" size="xsmall" srLabel="Acceder a la cuenta" />
        )}
        {message.description}
      </Typography>
    </div>
  );
};

Feedback.defaultProps = {
  email: '',
  name: '',
  traficOrigin: '',
  type: FORM_STATUS.SUCCESS,
};

Feedback.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
  traficOrigin: PropTypes.string,
  type: PropTypes.oneOf([
    FORM_STATUS.ERROR,
    FORM_STATUS.IS_RESELLER,
    FORM_STATUS.PENDING,
    FORM_STATUS.RESEND_EMAIL,
    FORM_STATUS.SUCCESS_HAS_ACCOUNT_AND_NOT_LOGGED,
    FORM_STATUS.SUCCESS,
  ]),
};

module.exports = Feedback;
