// i18nLocalesOverride:['pt-BR']

const { MLB } = require('../../../../../../services/constants/groupId');

module.exports = (i18n, prizesConfigs) => {
  const getConfigsPerGroupId = (id) => prizesConfigs?.find((item) => item?.group_id === id) || {};

  return {
    title: i18n.gettext('También cuentas con incentivos en dinero'),
    items: [
      {
        id: 1,
        icon: 'point-mobile.jpg',
        description: `Ganhe R$
        ${getConfigsPerGroupId(MLB.APRENDIZ).prizes?.device_new_client_cashback || 0}
        em até 5 dias úteis por Point que atingir um valor em vendas.`,
        alt: 'maquininha de cartão',
      },
      {
        id: 2,
        icon: 'vendas-mobile.jpg',
        description: i18n.gettext(
          'Gana hasta un {0}% de la facturación mensual de los Point vendidos',
          (
            getConfigsPerGroupId(MLB.TOP_EMPREENDEDOR).prizes
              ?.tpv_percentage_by_transacted_amount_range || 0
          ).toLocaleString('pt-BR'),
        ),
        alt: 'mão recebendo dinheiro',
      },
    ],
  };
};
