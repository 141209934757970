// i18nLocalesOverride:['es-AR','es-MX','es-CL']

module.exports = (siteId, i18n) => {
  if (siteId === 'MLC') {
    return {
      title: i18n.gettext('Conoce revendedores que lo están logrando'),
      videoSrc: 'https://www.youtube.com/embed/me-i-y5YgtA?si=hm4eCkP4qGBvhgo9',
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'Otros representantes ya lo están logrando',
      videoSrc: 'https://www.youtube.com/embed/XIw0K_5jAKc',
    };
  }

  return {
    title: 'Hacé que tu negocio llegue a donde vos querés',
    subtitle: 'Conocé la experiencia de otros revendedores.',
    videoSrc: 'https://www.youtube.com/embed/WefXgunDoQA',
  };
};
