// i18nLocalesOverride:['es-MX']

const React = require('react');

const Image = require('nordic/image');
const Typography = require('@andes/typography');

const LevelItem = require('../../../../Molecules/ItemDetails');
const CardDivider = require('../../../../Atoms/CardDivider');

const configs = require('./config');

const Levels = () => {
  const { title, imageSrc, items } = configs;

  return (
    <div className="levels__wrapper">
      <div className="levels">
        <div className="levels__content">
          <CardDivider />
          <Typography component="h3" size="l" type="title">
            {title}
          </Typography>
          <div className="levels-image">
            <Image height={532} src={imageSrc} width={510} />
          </div>
          {items.map((item) => (
            <LevelItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

module.exports = Levels;
