const API_RESPONSE_TYPE = {
  ERROR_ACCOUNT_BLOCKED: 'ERROR_ACCOUNT_BLOCKED',
  ERROR_ACCOUNT_DUPLICATED: 'ERROR_ACCOUNT_DUPLICATED',
  ERROR: 'ERROR',
  SUCCESS_WITH_ACCOUNT: 'SUCCESS_WITH_ACCOUNT',
  SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL: 'SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL',
  SUCCESS_WITHOUT_ACCOUNT: 'SUCCESS_WITHOUT_ACCOUNT',
};

module.exports = API_RESPONSE_TYPE;
