// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Card } = require('@andes/card');
const { Image } = require('nordic/image');
const { Text, Title } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');

const configs = require('./config');

const Incentives = ({ prizesConfigs = [] }) => {
  const { i18n } = useI18n();

  const { title, items } = configs(i18n, prizesConfigs);

  return (
    <div className="incentives-program-wrapper">
      <div className="incentives-program-wrapper__container">
        <Title component="h2" type="title">
          {title}
        </Title>
        <div className="incentives-program-wrapper__container-items">
          {items.map((item) => (
            <Card key={item.id} shadow="elevated">
              <Image alt={item.alt} src={item.icon} width={70} />
              <div>
                {item.title && (
                  <Title component="h3" size="s">
                    {item.title}
                  </Title>
                )}
                <Text component="p">{item.description}</Text>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

Incentives.propTypes = {
  prizesConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      group_name: PropTypes.string,
      group_id: PropTypes.number,
      prizes: PropTypes.shape({
        device_activation_max_earning: PropTypes.number,
        new_level_bonus_amount: PropTypes.number,
        device_activation_earning_amount: PropTypes.number,
        tpv_percentage_earn_prize: PropTypes.number,
        tpv_max_earn_prize: PropTypes.number,
        max_prize_amount_per_month: PropTypes.number,
      }),
    }),
  ),
};

module.exports = Incentives;
