// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const Image = require('nordic/image');
const Typography = require('@andes/typography');
const CarouselFree = require('@andes/carousel-free');
const CarouselSnapped = require('@andes/carousel-snapped');

const { CarouselSnappedSlide } = CarouselSnapped;
const { CarouselFreeSlide } = CarouselFree;

const ItemDetails = require('../../../../Molecules/ItemDetails');
const CardDivider = require('../../../../Atoms/CardDivider');

const configs = require('./config');

const Levels = ({ deviceType }) => {
  const { title, items, observation } = configs;

  return (
    <div className="levels__wrapper">
      <div className="levels">
        <div className="levels__content">
          <CardDivider />
          <Typography component="h2" size="l" type="title">
            {title}
          </Typography>

          <Image alt="color effect" className="background-effect__min" src="effect-1.png" />
          <Image alt="color effect" className="background-effect__max" src="effect-2.png" />

          {deviceType === 'mobile' ? (
            <CarouselFree>
              {items.map((item) => (
                <CarouselFreeSlide key={item.title}>
                  <ItemDetails {...item} />
                </CarouselFreeSlide>
              ))}
            </CarouselFree>
          ) : (
            <CarouselSnapped
              arrows
              pagination={deviceType === 'tablet'}
              scroll="hidden"
              slidesPerView={deviceType === 'tablet' ? 4 : 5}
            >
              {items.map((item) => (
                <CarouselSnappedSlide key={item.title}>
                  <ItemDetails {...item} />
                </CarouselSnappedSlide>
              ))}
            </CarouselSnapped>
          )}

          <Typography className="observation" component="p" size="xs">
            {observation}
          </Typography>
        </div>
      </div>
    </div>
  );
};

Levels.defaultProps = {
  deviceType: 'mobile',
};

Levels.propTypes = {
  deviceType: PropTypes.string,
};

module.exports = Levels;
