const formatThousands = (number, decimalSeparator = '.') => {
  if (!number) return '0';
  const numberString = number.toString();

  return numberString.replace(
    /(\d)(?=(\d\d\d)+(?!\d))/g,
    `$1${decimalSeparator === ',' ? '.' : ','}`,
  );
};

const formatConfig = {
  MLB: {
    country: 'pt-BR',
    currency: 'BRL',
  },
  MLA: {
    country: 'es-AR',
    currency: 'ARS',
  },
  MLM: {
    country: 'es-MX',
    currency: 'MXN',
  },
  MLC: {
    country: 'es-CL',
    currency: 'CLP',
  },
};

const formatterPrice = (number, siteId = 'MLC') =>
  new Intl.NumberFormat(formatConfig[siteId?.toString()]?.country, {
    currency: formatConfig[siteId?.toString()].currency,
    style: 'currency',
  }).format(number || 0);

const formatPriceString = (price, siteId = 'MLA', codeSpace = false) => {
  const country = {
    MLB: 'pt-BR',
    MLA: 'es-AR',
    MLM: 'es-MX',
    MLC: 'es-CL',
  };

  const currency = {
    MLB: 'R$',
    MLA: '$',
    MLM: '$',
    MLC: '$',
  };

  return `${currency[siteId]}${codeSpace ? '&nbsp;' : ' '}${(+price || 0).toLocaleString(
    country[siteId],
  )}`;
};

const formatPhoneNumberMLA = (number) => {
  let mask = [];

  switch (number) {
    case 2:
      mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      break;
    case 3:
      mask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      break;
    case 4:
      mask = [
        '(',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
      break;
    default:
      mask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  return mask;
};

module.exports = {
  formatThousands,
  formatterPrice,
  formatPhoneNumberMLA,
  formatPriceString,
};
