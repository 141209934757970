const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');

const Background = ({ deviceType = 'mobile', position = 'top' }) => {
  const imageUrl = {
    top: deviceType === 'mobile' ? 'bg-top-blue-wave-mobile.webp' : 'bg-top-blue-wave.webp',
    bottom:
      deviceType === 'mobile' ? 'bg-bottom-blue-wave-mobile.webp' : 'bg-bottom-blue-wave.webp',
  };

  const imageFallbackUrl = {
    top: deviceType === 'mobile' ? 'bg-top-blue-wave-mobile.png' : 'bg-top-blue-wave.png',
    bottom: deviceType === 'mobile' ? 'bg-bottom-blue-wave-mobile.png' : 'bg-bottom-blue-wave.png',
  };

  return <Image alt="" src={imageUrl[position]} srcFallback={imageFallbackUrl[position]} />;
};

Background.propTypes = {
  deviceType: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom']),
};

module.exports = Background;
