// i18nLocalesOverride:['es-AR','es-MX']

module.exports = (siteId) => {
  if (siteId === 'MLM') {
    return {
      title: '¿Todavía tienes dudas?',
      subtitle: 'Chatea con Pogo, el asistente virtual de Mercado Pago',
      image: {
        width: 320,
        height: 330,
        src: 'dudas-bg.png',
      },
      text: 'Pregúntale por WhatsApp sobre los premios, las terminales y cómo convertirte en representante.',
      action: {
        url: 'https://api.whatsapp.com/send/?phone=525524946542&text&app_absent=0',
        text: 'Chatear con Pogo',
      },
      tyc: {
        text: 'Para más información revisa los ',
        linkUrl: 'https://www.mercadopago.com.mx/ayuda/5251',
        linkText: 'Términos y condiciones',
      },
    };
  }

  return {
    title: '¿Todavía tienes dudas?',
    subtitle: 'Chateá con Pogo, el asistente virtual del Programa de Revendedores de Mercado Pago',
    image: {
      width: 320,
      height: 330,
      src: 'dudas-bg.png',
    },
    text: 'Vas a poder consultar los premios, los productos y conocer la experiencia de otros revendedores.',
    action: {
      url: 'https://api.whatsapp.com/send/?phone=5491164145973&text&app_absent=0',
      text: 'Chatear con Pogo',
    },
    tyc: {
      text: 'Para más información revisa los ',
      linkUrl: 'https://www.mercadopago.com.ar/ayuda/5248',
      linkText: 'Términos y condiciones',
    },
  };
};
