// i18nLocalesOverride:['es-AR']

module.exports = (i18n) => {
  return {
    title: i18n.gettext('Preguntas frecuentes'),
    faqs: [
      {
        title: i18n.gettext('¿EN QUÉ CONSISTE EL PROGRAMA?'),
        description: i18n.jsx.gettext(
          'Como participante del programa, accedés a precios promocionales para la compra mayorista de dispositivos Point Mini, Point Smart y Kit QR, para luego revenderlos al precio de mercado sugerido. Además, podés acceder a distintos premios por:{0}Nuevos clientes Point y QR.{1}{0}Actividad de tus clientes.{1}{0}Descuento por compras frecuentes.{1}Ingresá en nuestra {2}nota del centro de vendedores{3} y conocé todos los detalles del programa.',
          {
            tags: {
              0: '<span class="list">',
              1: '</span>',
              2: '<a href="https://sites.google.com/view/academiarevendedores/inicio">',
              3: '</a>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿CUÁLES SON LOS REQUISITOS PARA REVENDER MERCADO PAGO?'),
        description: i18n.jsx.gettext(
          'No necesitas  experiencia previa ni horarios fijos, solo ganas de ayudar a pequeños comerciantes y emprendedores a incorporar a Mercado Pago como una nueva solución de cobro con tarjetas. Lo que necesitas es tener una cuenta en Mercado Pago y aceptar los {0}términos y condiciones del programa{1}.',
          {
            tags: {
              0: '<a href="https://www.mercadopago.com.ar/ayuda/5248">',
              1: '</a>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿CUÁNDO Y CÓMO PUEDO EMPEZAR A COMPRAR?'),
        description: i18n.jsx.gettext(
          '¡Te inscribís completando este {0}formulario{1} y empezás a comprar en el momento! Se te habilitará una sección de revendedores en tu app de Mercado Pago para que puedas comprar tus lectores.',
          {
            tags: {
              0: '<span id="form-redirect" class="form-redirect">',
              1: '</span>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿CUÁL ES EL PRECIO MAYORISTA A LOS QUE COMPRARÍA LOS POINT y QR?'),
        description: i18n.gettext(
          'Una vez que te inscribás al programa, tendrás acceso al sitio de revendedores en tu cuenta de Mercado Pago, donde está toda la información detallada sobre los costos e incentivos adicionales. Vas a poder aprovechar hasta un 60% de descuento en la compra de tus lectores.',
        ),
      },
      {
        title: i18n.gettext('¿CÓMO PUEDO CAPACITARME PARA VENDER?'),
        description: i18n.jsx.gettext(
          'Para capacitarte como Revendedor Point y QR, te recomendamos:{0}Leer todas las preguntas y respuestas frecuentes.{1}{0}Explorar la sección de {2}novedades{3} dentro del sitio de Revendedores.{1}{0}Acceder a las capacitaciones de la {4}Academia de Revendedores.{5}{1}{0}Participar en grupos de WhatsApp con otros revendedores y con el equipo de Mercado Pago para acompañarte en todo tu camino como Revendedor.{1}{0}Inscribirte en las capacitaciones mensuales para nuevos revendedores{1}{0}Explorar las notas en el {6}Centro de Vendedores{7} y el {8}Portal de Ayuda{9}.{1}{0}Ponerte en contacto con tu líder, un revendedor con mucha experiencia en el programa y quien está dispuesto a resolver tus consultas, compartir su experiencia y brindarte consejos para que podás incrementar tus ventas.{1}',
          {
            tags: {
              0: '<span class="list">',
              1: '</span>',
              2: '<a href="https://www.mercadopago.com.ar/resellers/novedades-ar">',
              3: '</a>',
              4: '<a href="https://cursos-vendedores.mercadolibre.com.ar/">',
              5: '</a>',
              6: '<a href="https://sites.google.com/view/academiarevendedores/inicio">',
              7: '</a>',
              8: '<a href="https://www.mercadopago.com.ar/ayuda">',
              9: '</a>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿DÓNDE PUEDO VENDER?'),
        description: i18n.jsx.gettext(
          'Cada Revendedor es independiente y puede vender en forma presencial (sin restricciones ni exclusividad geográficas) o en forma online en distintas plataformas.{0}{1}{3}Presencial:{4}{1}Visitando comercios de potenciales clientes{2}{1}Ofreciendo los dispositivos en su propio negocio{2}{1}Recomendando los dispositivos a colegas{2}{1}Entregando folletos en la vía pública o en diarios locales{2}{1}Realizando acuerdos con empresas o cámaras locales{2}{1}Organizando capacitaciones con potenciales clientes{2}{2}{1}{3}Online:{4}{1}Promocionando en redes sociales (Facebook, Instagram, WhatsApp, otros){2}{1}Ofreciéndolos en sitios web propios{2}{1}Publicando los dispositivos en plataformas de comercio electrónico{2}{1}Enviando correos electrónicos a potenciales clientes{2}{2}',
          {
            tags: {
              0: '<br/>',
              1: '<span class="list">',
              2: '</span>',
              3: '<strong>',
              4: '</strong>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿A QUÉ PRECIO DEBO VENDERLOS?'),
        description: i18n.jsx.gettext(
          'El Revendedor puede tomar como precio sugerido de venta aquel al que Mercado Pago realiza la venta de los dispositivos {0}nuestra página web{1}. Esto ayudará a asegurar un buen nivel de activación, requisito de permanencia en el programa.',
          {
            tags: {
              0: '<a href="https://www.mercadopago.com.ar/point">',
              1: '</a>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿TENGO QUE REGISTRARLOS?'),
        description: i18n.gettext(
          'No es necesario registrar las ventas para acceder a tus premios. Mercado Pago ya sabe que dispositivos compró el revendedor, tanto como, cuando y cuanto cobra ese dispositivo.',
        ),
      },
      {
        title: i18n.gettext('¿QUÉ CARACTERÍSTICAS TIENE CADA DISPOSITIVO?'),
        description: i18n.jsx.gettext(
          '{3}Point Mini:{4}{0}{3}Conectividad:{4} Bluetooth al celular{1}{0}{3}Tecnología de cobro:{4}NFC, Chip y Banda magnética{1}{0}{3}Beneficios:{4}{0}Duración de batería{1}{0}Indicadores LED de funcionamiento y procesamiento{1}{1}{0}{3}Perfil del cliente:{4}{0}Emprendedores{1}{0}Servicios Profesionales{1}{0}Cuentapropistas{1}{1}{2}{3}Point Smart:{4}{0}{3}Conectividad:{4} 4G, Wifi{1}{0}{3}Tecnología de cobro:{4} Banda, Chip, NFC y QR{1}{0}{3}Beneficios:{4}{0}Independiente del teléfono{1}{0}Sistema Operativo Android{1}{0}Pantalla Táctil{1}{0}Catálogo de Productos{1}{0}Impresión de Comprobantes{1}{0}Duración de batería{1}{1}{0}{3}Perfil del cliente:{4}{0}Comercios Medianos{1}{0}Grandes Comercios{1}{1}{2}{3}Kit QR:{4}{0}{3}Conectividad:{4} N/A{1}{0}{3}Tecnología de cobro:{4} QR{1}{0}{3}Beneficios:{4}{0}Interoperabilidad - Acepta pagos de otras billeteras{1}{0}Comprobante virtual{1}{0}Velocidad de procesamiento{1}{0}Seguridad al momento de cobro{1}{0}Podés ofrecer descuentos a los clientes{1}{1}{0}{3}Perfil del cliente:{4}{0}Servicios Profesionales{1}{0}Comercios Medianos{1}{0}Grandes Comercios{1}{1}{2}',
          {
            tags: {
              0: '<span class="list">',
              1: '</span>',
              2: '<br/>',
              3: '<strong>',
              4: '</strong>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿PUEDO CRECER EN EL PROGRAMA?'),
        description: i18n.jsx.gettext(
          'Si, a medida que vendés más y conseguís nuevos clientes, accedés a nuevas herramientas y beneficios para seguir creciendo. ¡Te mostramos todo lo que podes crecer!{0}1. {2}Aprendiz:{3} Empezá tu camino como revendedor y ganá hasta $ {4} por margen de venta de cada Point y hasta $ {5} más en premios.{1}{0}2. {2}Emprendedor:{3} Ganá hasta $ {6} en premios mensuales consiguiendo nuevos clientes y la activación de tus lectores.{1}{0}3. {2}Experto:{3} Ganá hasta $ {7} por sumar grandes comercios a Point y QR, con herramientas exclusivas para vos.{1}{0}4. {2}Preconsultor:{3} Ganá hasta $ {8} por asesor a grandes comercios. Además, si tenés un perfil de líder, podés ganar premios por acompañar a los nuevos revendedores en su camino de crecimiento en el programa.{1}{0}5. {2}Consultor Certificado:{3} Convertite en un referente de Mercado Pago en tu localidad y ganá más de $ {9} en premios por la atención post-venta de comercios.{1}',
          {
            tags: {
              0: '<span class="levels-list">',
              1: '</span>',
              2: '<strong>',
              3: '</strong>',
            },
            replacements: {
              4: '2.500',
              5: '1.800',
              6: '50.000',
              7: '100.000',
              8: '150.000',
              9: '250.000',
            },
          },
        ),
      },
    ],
  };
};
