// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { useState } = React;

const { Card } = require('@andes/card');

const LoggedUserRegistration = require('../../../../Organisms/Shared/LoggedUserRegistration');

const Actions = require('./actions');
const Feedback = require('./feedback');
const UnloggedForm = require('./unloggedForm');

const Formulary = ({ traficOrigin, user }) => {
  const [apiResponseType, setApiResponseType] = useState('');
  const [errors, setErrors] = useState([]);

  const [name, setName] = useState(user?.fullName || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [email, setEmail] = useState(user?.email || '');

  const userLogged = user?.email && user?.email !== '';

  const encodedURL = encodeURIComponent(
    `https://www.mercadopago.com.mx/representantes?${traficOrigin?.replace('&', '')}`,
  );

  const hasRgrParam = traficOrigin?.includes('utm_source=RGR');

  const userData = {
    name,
    email,
    phone,
    logged: !!user.email,
    isReseller: user?.isReseller,
  };

  const formComponent = userLogged ? (
    <LoggedUserRegistration
      callbackUrl={`https://www.mercadolibre.com/jms/mlm/lgz/logout?go=${encodedURL}`}
      email={email}
      name={name?.split(' ')[0] || name}
    />
  ) : (
    <UnloggedForm
      errors={errors}
      hasValidationEmail={hasRgrParam}
      onSetEmail={setEmail}
      onSetErrors={setErrors}
      onSetName={setName}
      onSetPhone={setPhone}
    />
  );

  return (
    <Card
      className="form__card"
      data-feedback={!!apiResponseType}
      paddingsize={32}
      shadow={apiResponseType ? 'elevated' : ''}
    >
      {apiResponseType ? (
        <Feedback email={email} name={name?.split(' ')[0] || name} type={apiResponseType} />
      ) : (
        formComponent
      )}
      <Actions
        apiResponseType={apiResponseType}
        errors={errors}
        traficOrigin={traficOrigin}
        user={userData}
        onSetApiResponseType={setApiResponseType}
      />
    </Card>
  );
};

Formulary.defaultProps = {
  user: {
    email: '',
    phone: '',
    fullName: '',
    isReseller: false,
  },
  traficOrigin: '',
};

Formulary.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    isReseller: PropTypes.bool,
  }),
  traficOrigin: PropTypes.string,
};

module.exports = Formulary;
