// i18nLocalesOverride:['es-AR','es-MX','es-CL','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const Recaptcha = require('frontend-recaptcha');

const RecaptchaComponent = ({ countryId = 'AR', errors = [] }) => {
  return (
    <Recaptcha
      required
      countryId={countryId}
      errors={errors}
      lowEnd={false}
      siteKey="6LcJYIMmAAAAABWwtUwJZtcG06MTFWruN_eE2Adi"
    />
  );
};

RecaptchaComponent.propTypes = {
  countryId: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
};

module.exports = RecaptchaComponent;
