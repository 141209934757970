// i18nLocalesOverride:['es-MX']

const React = require('react');

const Image = require('nordic/image');
const Typography = require('@andes/typography');

const QRItem = require('../../../../Molecules/ItemDetails');

const configs = require('./config');

const PointInfo = () => {
  const { title, imageSrc, items } = configs;

  return (
    <div className="point__wrapper">
      <div className="point">
        <div className="point-content">
          <Typography component="h3" size="l" type="title">
            {title}
          </Typography>
          <div className="point-image">
            <Image src={imageSrc} />
          </div>
          {items.map((item) => (
            <QRItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

module.exports = PointInfo;
