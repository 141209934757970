// i18nLocalesOverride:['pt-BR']

const React = require('react');

const { Button } = require('@andes/button');
const { Text, Title } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');
const ChevronUp16 = require('@andes/icons/ChevronUp16');

const tracking = require('../../../../../../services/utils/tracking');

const Footer = () => {
  const { i18n } = useI18n();

  const handleScroll = (position) => window?.scrollTo(0, position);

  const svgIcon = (
    <svg
      fill="none"
      height="41"
      viewBox="0 0 154 41"
      width="154"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.9525 0C42.7661 0 53.0044 40.9851 0.0917969 40.9851H153.908C104.071 40.9851 113.139 0 77.9525 0Z"
        fill="#068EE4"
      />
    </svg>
  );

  return (
    <div className="footer-wrapper">
      <div
        aria-label="Ir para o formulário de cadastro"
        className="footer-wrapper__chevron-top"
        role="button"
        tabIndex={-1}
        onClick={() => {
          tracking('home', 'footer-scroll-to-top');

          handleScroll(0);
        }}
        onKeyDown={() => {}}
      >
        <ChevronUp16 className="chevron" color="#ffffff" />
        {svgIcon}
      </div>

      <div className="footer-wrapper__content">
        <Title component="h3">{i18n.gettext('¡CONVIÉRTETE EN REVENDEDOR!')}</Title>

        <Text component="p" size="xs" weight="semibold">
          {i18n.gettext('Gana dinero con los lectores Point.')}
        </Text>

        <Button
          hierarchy="transparent"
          onClick={() => {
            tracking('home', 'footer-button');

            handleScroll(0);
          }}
        >
          {i18n.gettext('Quiero ser revendedor')}
        </Button>
      </div>
    </div>
  );
};

module.exports = Footer;
