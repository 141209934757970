/* eslint-disable import/order */

const React = require('react');

const { useCallback, useRef } = React;

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/useFormContext');

const { Button } = require('@andes/button');
const { Card } = require('@andes/card');
const { Form } = require('@andes/form');
const { TextField } = require('@andes/textfield');
const { Title } = require('@andes/typography');

const Tyc = require('../Tyc');
const Recaptcha = require('../../../../../Molecules/Recaptcha');

const masks = require('@andes/textfield/build/masks');
const useMask = require('@andes/textfield/build/hooks/useMask');

const validators = require('../../utils/validators');
const tracking = require('../../../../../../../services/utils/tracking');

const UnloggedUser = () => {
  const { i18n } = useI18n();

  const {
    errors = [],
    onSetEmail,
    onSetErrors,
    onSetName,
    onSetPhone,
    onSetTyc,
    recaptchaError,
    showErrors,
    user,
    validateForm,
  } = useFormContext();

  const hasMailError = showErrors && errors?.includes('MAIL');
  const hasNameError = showErrors && errors?.includes('NAME');
  const hasPhoneError = showErrors && errors?.includes('PHONE');
  const hasRecaptchaError = showErrors && recaptchaError;

  const phoneFieldRef = useRef();

  useMask(phoneFieldRef, { maskOptions: masks.BR.MOBILE });

  const fieldInteractionTracking = (field) => tracking('home', `form-${field}-interaction`);

  const handleError = useCallback(
    (type = 'add', key) => {
      let newErrors = errors;

      if (type === 'remove') {
        newErrors = errors.length ? errors.filter((er) => er !== key) : [];
      } else {
        newErrors.push(key);
      }

      onSetErrors(newErrors);
    },
    [errors, onSetErrors],
  );

  const validateEmail = useCallback(
    (event) => {
      const { value } = event.target;

      const validEmail = validators.validateEmail(value);

      handleError(validEmail ? 'remove' : 'add', 'MAIL');

      onSetEmail(value);
    },
    [handleError, onSetEmail],
  );

  const validateName = useCallback(
    (event) => {
      const { value } = event.target;

      const isValid = validators.validateName(value);

      handleError(isValid ? 'remove' : 'add', 'NAME');

      onSetName(value);
    },
    [handleError, onSetName],
  );

  const validatePhone = React.useCallback(
    (event) => {
      const { value } = event.target;

      const isValid = validators.validatePhone(value);

      handleError(isValid ? 'remove' : 'add', 'PHONE');

      onSetPhone(value);
    },
    [handleError, onSetPhone],
  );

  const handleChangeTyc = () => {
    handleError('remove', 'TYC');

    onSetTyc((state) => !state);

    tracking('home', 'click-on-terms-and-conditions');
  };

  const submit = async (e) => {
    tracking('home', 'form-register-i-want-to-be-a-reseller');

    validateForm(e);
  };

  return (
    <Card className="unlogged-wrapper">
      <Title className="mb-8 mt-0 block" component="h2" size="xs">
        {i18n.gettext('Ingresa tus datos para iniciar tu registro')}
      </Title>

      <Form onSubmit={submit}>
        <TextField
          defaultValue={user.name}
          helper={hasNameError ? 'Revise seu nome e sobrenome.' : ''}
          modifier={hasNameError ? 'error' : undefined}
          placeholder="Nome e sobrenome"
          srLabel="Nome e sobrenome"
          onBlur={(target) => {
            if (target.value) {
              fieldInteractionTracking('name');
            }
          }}
          onChange={validateName}
        />

        <TextField
          defaultValue={user.email}
          helper={hasMailError ? 'Revise seu e-mail.' : ''}
          modifier={hasMailError ? 'error' : undefined}
          placeholder="E-mail"
          srLabel="E-mail"
          onBlur={(target) => {
            if (target.value) {
              fieldInteractionTracking('email');
            }
          }}
          onChange={validateEmail}
        />

        <TextField
          ref={phoneFieldRef}
          defaultValue={user.phone}
          helper={hasPhoneError ? 'Revise seu número de WhatsApp.' : ''}
          modifier={hasPhoneError ? 'error' : undefined}
          placeholder={i18n.gettext('Número de WhatsApp')}
          srLabel={i18n.gettext('Número de WhatsApp')}
          onBlur={({ target }) => {
            if (target.value) {
              fieldInteractionTracking('phone');
            }
          }}
          onChange={validatePhone}
        />

        <Tyc isChecked={user.tyc} onHandleChange={handleChangeTyc} />

        <Recaptcha
          countryId="BR"
          errors={
            hasRecaptchaError
              ? [i18n.gettext('Es obligatorio rellenar este campo para avanzar.')]
              : []
          }
        />

        <Button fullWidth type="submit">
          {i18n.gettext('Iniciar registro')}
        </Button>
      </Form>
    </Card>
  );
};

module.exports = UnloggedUser;
