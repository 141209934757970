// i18nLocalesOverride:['pt-BR']

module.exports = (i18n, deviceType) => {
  return {
    title: i18n.gettext('Ventajas de ser un revendedor de Mercado Pago'),
    image: {
      alt: 'Vantagens de ser um revendedor de Mercado Pago',
      src: deviceType === 'mobile' ? 'vantagens-mobile.webp' : 'vantagens.webp',
      srcFallback: deviceType === 'mobile' ? 'vantagens-mobile.png' : 'vantagens.png',
    },
    items: [
      {
        title: i18n.gettext('Flexibilidad para decidir cuándo y cómo vender.'),
      },
      {
        title: i18n.gettext('Capacitación para ayudarte a crecer en el programa.'),
      },
      {
        title: i18n.gettext('Envío gratis en tus compras de lectores Point.'),
      },
      {
        title: i18n.gettext('Point con descuento para que ganes con la reventa.'),
      },
    ],
  };
};
