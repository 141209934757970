// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Button } = require('@andes/button');
const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const tracking = require('../../../../../../services/utils/tracking');
const { MICROSITIO_URL } = require('../../../../../../services/constants/externalUrls');

const HeaderFixed = ({ deviceType, isReseller = false }) => {
  const { i18n } = useI18n();

  const [showFixedHeader, setShowFixedHeader] = React.useState(false);

  React.useEffect(() => {
    const handleHideHeader = () => {
      const threshold = 100;
      const shouldShowFixedHeader = window.scrollY > threshold;

      setShowFixedHeader(shouldShowFixedHeader);
    };

    window.addEventListener('scroll', handleHideHeader);

    return () => {
      window.removeEventListener('scroll', handleHideHeader);
    };
  }, []);

  const handleClick = () => {
    if (isReseller) {
      tracking('home', 'button-header-fixed-go-to-portal');

      window.location.href = MICROSITIO_URL.MLB;

      return;
    }

    tracking('home', 'button-header-fixed');

    window.scrollTo(0, 0);
  };

  return (
    showFixedHeader && (
      <div className="header-fixed">
        <section>
          <Image
            height={deviceType === 'mobile' ? 26 : 38}
            src={
              deviceType === 'mobile'
                ? 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/Mercado%20Pago--246b1bbf.jpg'
                : 'https://http2.mlstatic.com/frontend-assets/mp-web-navigation/ui-navigation/5.19.1/mercadopago/logo__large@2x.png'
            }
            width="auto"
          />
          <Button hierarchy="loud" onClick={() => handleClick()}>
            {isReseller ? i18n.gettext('Ir al portal') : i18n.gettext('Quiero ser revendedor')}
          </Button>
        </section>
      </div>
    )
  );
};

HeaderFixed.propTypes = {
  deviceType: PropTypes.string.isRequired,
  isReseller: PropTypes.bool,
};

module.exports = HeaderFixed;
