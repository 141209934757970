const mlm = require('./mlm');
const mlc = require('./mlc');
const mlb = require('./mlb');
const mla = require('./mla');

module.exports = (i18n, devices, siteId, configs) => {
  if (siteId === 'MLB') {
    return mlb(i18n, configs, devices);
  }

  if (siteId === 'MLC') {
    return mlc(i18n, devices);
  }

  if (siteId === 'MLM') {
    return mlm(i18n, devices);
  }

  return mla(i18n);
};
