// i18nLocalesOverride:['es-CL']

const React = require('react');

const Image = require('nordic/image');

const { formatterPrice } = require('../../../../../../services/utils/formatter');
const { getBetterActivationPrize } = require('../../../../../../services/utils/prizes');

module.exports = (groupConfigs, devicesConfig, i18n) => {
  const purchasesPrize = formatterPrice(+groupConfigs?.frequent_purchases_earning_amount || 0);

  const purchasesQuantity = groupConfigs?.frequent_purchases_device_quantity || 0;

  const activationPrize = formatterPrice(+getBetterActivationPrize(devicesConfig?.configs) || 0);

  return {
    title: i18n.gettext('Conoce lo que puedes ganar'),
    prizes: [
      {
        type: 'benefit',
        icon: <Image src="point-usage-2.png" />,
        title: i18n.gettext('Margen de venta'),
        description: i18n.gettext(
          'Gana hasta {0} de margen de ganancia al revender lectores de tarjeta Point.',
          '$ 55.000',
        ),
      },
      {
        type: 'benefit',
        icon: <Image src="mlc-frequency-prize.png" />,
        title: i18n.gettext('Premio de activación'),
        description: i18n.gettext(
          'Gana hasta {0} por cada Point que cobre un monto en un periodo establecido.',
          '$ 440.000',
        ),
      },
    ],
  };
};
