const FORM_STATUS = {
  ERROR: 'ERROR',
  IN_VALIDATION: 'IN_VALIDATION',
  IS_RESELLER: 'IS_RESELLER',
  PENDING: 'PENDING',
  PENDING_MP_ACCOUNT: 'PENDING_MP_ACCOUNT',
  RESEND_EMAIL: 'RESEND_EMAIL',
  SUCCESS_HAS_ACCOUNT_AND_NOT_LOGGED: 'SUCCESS_HAS_ACCOUNT_AND_NOT_LOGGED',
  SUCCESS_HAS_ACCOUNT: 'SUCCESS_HAS_ACCOUNT',
  SUCCESS: 'SUCCESS',
};

module.exports = FORM_STATUS;
