// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { useCallback, useState } = React;

const { TextField } = require('@andes/textfield');
const MaskTextField = require('@andes/masktextfield');

const { searchUser } = require('../../../../../../services/user');
const { phoneMLM } = require('../../../../../../services/constants/phone');

const UnloggedForm = ({
  errors,
  onSetErrors,
  onSetEmail,
  onSetName,
  onSetPhone,
  hasValidationEmail,
}) => {
  const [inputMessage, setInputMessage] = useState('');
  const [inputModifier, setInputModifier] = useState('default');

  const fetchUser = useCallback(
    async (email) => {
      if (hasValidationEmail) {
        const userResp = await searchUser(email);

        if (!userResp) {
          onSetErrors(errors.filter((er) => er !== 'HAS_MAIL') || []);
          setInputMessage('');
          setInputModifier('default');

          return;
        }

        setInputMessage(userResp ? 'Este e-mail tiene cuenta en Mercado Pago.' : '');
        setInputModifier(userResp ? 'error' : 'default');
        onSetErrors([...errors, 'HAS_MAIL']);
      }
    },
    [hasValidationEmail, onSetErrors, errors],
  );

  const validateEmail = useCallback(
    (event) => {
      const emailInput = event.target.value;

      if (emailInput.length <= 0 || emailInput.length <= 6 || emailInput.indexOf('@') <= 0) {
        onSetErrors([...errors, 'MAIL']);

        return;
      }

      onSetErrors(errors.filter((er) => er !== 'MAIL') || []);
    },
    [errors, onSetErrors],
  );

  const validatePhone = useCallback(
    (event) => {
      const field = event.target.value.replace(/\D+/g, '');

      if (field.length === 0 || field.length < 10) {
        onSetErrors([...errors, 'PHONE']);
      } else if (errors.includes('PHONE')) {
        onSetErrors(errors.filter((er) => er !== 'PHONE') || []);
      }

      onSetPhone(event.target.value);
    },
    [errors, onSetPhone, onSetErrors],
  );

  const validateName = useCallback(
    (event) => {
      const target = event.target.value;

      if (target.length === 0 || target.length <= 6 || !target.includes(' ')) {
        onSetErrors([...errors, 'NAME']);
      } else if (errors.includes('NAME')) {
        onSetErrors(errors.filter((er) => er !== 'NAME') || []);
      }

      onSetName(target);
    },
    [errors, onSetName, onSetErrors],
  );

  return (
    <div className="form__fields">
      <TextField
        helper={errors.includes('NAME') ? 'Revisa tu nombre y apellido.' : ''}
        label="Nombre y apellido"
        modifier={errors.includes('NAME') ? 'error' : 'default'}
        onChange={(e) => validateName(e)}
      />
      <TextField
        helper={errors.includes('MAIL') ? 'Revisa tu e-mail.' : inputMessage}
        label="E-mail"
        modifier={errors.includes('MAIL') ? 'error' : inputModifier}
        onBlur={async (event) => {
          const emailInput = event.target.value;

          if (emailInput.length <= 0 && !errors.includes('MAIL')) {
            return;
          }

          await fetchUser(emailInput);

          onSetEmail(emailInput);
        }}
        onChange={(e) => validateEmail(e)}
      />
      <MaskTextField
        helper={
          errors.includes('PHONE')
            ? 'Revisa tu número de celular.'
            : 'Compártenos tu número de WhatsApp y recibe una experiencia completa de inducción.'
        }
        label="Número de celular"
        mask={phoneMLM}
        modifier={errors.includes('PHONE') ? 'error' : 'default'}
        placeholder="Ej.: 11 23456789"
        onChange={(e) => validatePhone(e)}
      />
    </div>
  );
};

UnloggedForm.defaultProps = {
  errors: [],
  hasValidationEmail: false,
  onSetErrors: () => {},
  onSetEmail: () => {},
  onSetName: () => {},
  onSetPhone: () => {},
};

UnloggedForm.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.any),
  hasValidationEmail: PropTypes.bool,
  onSetErrors: PropTypes.func,
  onSetEmail: PropTypes.func,
  onSetName: PropTypes.func,
  onSetPhone: PropTypes.func,
};

module.exports = UnloggedForm;
