/* eslint-disable import/order */

const React = require('react');

const { Text } = require('@andes/typography');
const { useFormContext } = require('../context/useFormContext');

const Close12 = require('@andes/icons/Close12');
const User16 = require('@andes/icons/User16');

const tracking = require('../../../../../../services/utils/tracking');

const { LANDING_PAGE_URL } = require('../../../../../../services/constants/externalUrls');

const PillEmailLogged = () => {
  const { user, siteId, traficOrigin } = useFormContext();

  const logout = () => {
    const landingUrl = encodeURIComponent(
      `${LANDING_PAGE_URL[siteId]}?${traficOrigin?.replace('&', '')}`,
    );

    const logOutUrl = `https://www.mercadolivre.com/jms/mlb/lgz/logout?go=${landingUrl}`;

    tracking('home', 'form-logout');

    window.open(logOutUrl, '_self');
  };

  return (
    <div className="pill-logged-email">
      <div>
        <User16 />
      </div>
      <Text size="xs">{user.email}</Text>
      <button aria-label="logout" type="button" onClick={logout}>
        <Close12 />
      </button>
    </div>
  );
};

module.exports = PillEmailLogged;
