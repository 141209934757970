// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const Card = require('@andes/card');
const Typography = require('@andes/typography');

const { formatterPrice } = require('../../../../../../services/utils/formatter');

const { CALC_COLS } = require('./configs');

const Footer = ({ devicesTotals }) => {
  const getCalc = React.useCallback(() => {
    const totalDevices = devicesTotals?.reduce((res, d) => +res + +d.quantity, 0);
    let totalUsePrizes = 0;
    let totalActivationPrizes = 0;
    let totalProfit = 0;
    let totalPrice = 0;

    if (devicesTotals) {
      devicesTotals.forEach((d) => {
        totalPrice += d.price;

        CALC_COLS.forEach((calc) => {
          if (calc.type === 'prize' && calc.field === 'usage') {
            totalUsePrizes += +d[calc.field.toString()];
          }
          if (calc.type === 'prize' && calc.field === 'activation') {
            totalActivationPrizes += +d[calc.field.toString()];
          }
          if (calc.type === 'profit') {
            totalProfit += +d[calc.field.toString()];
          }
        });
      });
    }

    const totalGanacia = totalUsePrizes + totalActivationPrizes + totalProfit;

    return {
      totalPrice,
      totalDevices,
      totalUsePrizes,
      totalActivationPrizes,
      totalProfit,
      totalGanacia,
    };
  }, [devicesTotals]);

  const summary = getCalc();

  return (
    <>
      <div className="row row-total">
        <Typography className="cell bold" size="xl">
          Total
        </Typography>
        <Typography className="cell bold total-value" size="xl">
          {summary.totalDevices}
          {summary.totalDevices > 0 && ' Point'}
        </Typography>
        <Typography className="cell bold total-value" size="xl">
          {formatterPrice(summary.totalProfit || 0)}
        </Typography>
        <Typography className="cell bold total-value" size="xl">
          {formatterPrice(summary.totalActivationPrizes || 0)}
        </Typography>
      </div>

      <Card className="calculator__total">
        <div>
          <Typography component="p">Monto a invertir</Typography>
          <Typography component="h3" type="title">
            {formatterPrice(summary.totalPrice)}
          </Typography>
        </div>

        <div>
          <p>Ganancia</p>
          <Typography component="h3" type="title">
            {formatterPrice(summary.totalGanacia)}
          </Typography>

          {devicesTotals?.length > 0 && devicesTotals?.some((item) => +item?.quantity) && (
            <div className="devices-totals">
              por
              {devicesTotals
                ?.filter((item) => +item?.quantity)
                ?.map((item, index) => {
                  const concat = index === 0 ? '' : ' y';
                  const message = `${item?.quantity} ${item?.model}`;

                  return (
                    <Typography key={item.model} size="xs">
                      <Typography component="strong" size="xs">
                        {concat} {message}
                      </Typography>
                    </Typography>
                  );
                })}
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

Footer.defaultProps = {
  devicesTotals: [],
};

Footer.propTypes = {
  devicesTotals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      model: PropTypes.string,
      quantity: PropTypes.number,
      price: PropTypes.number,
      activation: PropTypes.number,
      profit: PropTypes.number,
    }),
  ),
};

module.exports = Footer;
