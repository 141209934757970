// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const Benefits = require('../../Organisms/Shared/Benefits');
const ButtonFixed = require('../../Molecules/ButtonFixed');
const Discover = require('../../Molecules/Discover');
const Fab = require('../../Molecules/Fab');
const FAQList = require('../../Organisms/Shared/FAQ');
const Footer = require('../../Organisms/Shared/Footer');
const HeaderFixed = require('../../Organisms/Shared/HeaderFixed');
const Hero = require('../../Organisms/Shared/Hero');
const Points = require('../../Organisms/Shared/Points');
const PointsInfo = require('../../Organisms/Shared/PointsInfo');
const Pogo = require('../../Organisms/Shared/Pogo');
const Timeline = require('../../Organisms/Shared/Timeline');
const Video = require('../../Organisms/Shared/Video');

const Calculator = require('./components/Calculator');
const Levels = require('./components/Levels');
const PointInfo = require('./components/PointInfo');
const Register = require('./components/Register');

const LandingMLM = ({ traficOrigin, deviceType, userData, data, devicesConfig }) => {
  const userLogged = userData?.email && userData?.email !== '';

  const { devicesOrder } = devicesConfig || {};

  const orderedDevices = Array.isArray(data.devices)
    ? data.devices.sort((a, b) => {
        const orderA = devicesOrder?.[a?.id] || 99;
        const orderB = devicesOrder?.[b?.id] || 99;

        return orderA - orderB || a?.id - b?.id;
      })
    : [];

  return (
    <div>
      <HeaderFixed isReseller={userData?.isReseller} siteId="MLM" />
      <Hero deviceType={deviceType} siteId="MLM" />
      <Register deviceType={deviceType} traficOrigin={traficOrigin} user={userData} />
      <Timeline deviceType={deviceType} siteId="MLM" />
      <Discover siteId="MLM" />
      <Points deviceType={deviceType} devices={orderedDevices} siteId="MLM" />
      <PointInfo />
      <Benefits deviceType={deviceType} siteId="MLM" />
      {data?.groups?.length > 0 && (
        <Calculator
          devicesConfig={devicesConfig}
          groups={data.groups?.filter((g) => g.level && g.level !== 0)}
        />
      )}
      <Levels />
      <Video siteId="MLM" />
      <PointsInfo siteId="MLM" />
      <Pogo siteId="MLM" />
      <FAQList devices={orderedDevices} siteId="MLM" />
      <Footer deviceType={deviceType} siteId="MLM" />
      <ButtonFixed deviceType={deviceType} isLogged={userLogged} siteId="MLM" />
      <Fab
        deviceType={deviceType}
        title="Resuelve tus dudas"
        url="https://api.whatsapp.com/send/?phone=525524946542&text&app_absent=0"
      />
    </div>
  );
};

LandingMLM.defaultProps = {
  traficOrigin: '',
  deviceType: '',
  userData: {},
  data: {},
  devicesConfig: {},
};

LandingMLM.propTypes = {
  deviceType: PropTypes.string,
  traficOrigin: PropTypes.string,
  userData: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    isReseller: PropTypes.bool,
  }),
  data: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
    devicesOrder: PropTypes.shape({}),
  }),
};

module.exports = LandingMLM;
