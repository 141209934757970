// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');

const Payment16 = require('@andes/icons/Payment16');
const Payment24 = require('@andes/icons/Payment24');
const History16 = require('@andes/icons/History16');
const History24 = require('@andes/icons/History24');
const Calendar16 = require('@andes/icons/Calendar16');
const Calendar24 = require('@andes/icons/Calendar24');
const StarOutlined24 = require('@andes/icons/StarOutlined24');
const OfficialStores24 = require('@andes/icons/OfficialStores24');

module.exports = (deviceType, siteId, i18n) => {
  const WHITE_COLOR = '#FFFFFF';

  if (siteId === 'MLC') {
    return {
      title: i18n.gettext('Gana dinero revendiendo lectores de tarjetas'),
      items: [
        {
          icon: <Payment24 color={WHITE_COLOR} />,
          text: 'Compra a precio preferencial.',
        },
        {
          icon: <History24 color={WHITE_COLOR} />,
          text: i18n.gettext('Maneja tus tiempos y gestiona tu propio negocio.'),
        },
        {
          icon: <StarOutlined24 color={WHITE_COLOR} />,
          text: 'No necesitas experiencia.',
        },
        {
          icon: <OfficialStores24 color={WHITE_COLOR} />,
          text: i18n.gettext('Crece junto a comercios locales y emprendedores.'),
        },
      ],
      image: {
        className: 'hero__image-reseller',
        height: 660,
        width: 700,
        src: 'img_hero-bg.png',
      },
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'Conviértete en representante y empieza a crecer',
      subtitle: 'Obtén ganancias por la venta de terminales y gana premios por tu desempeño.',
      items: [
        {
          icon: <Payment16 color={WHITE_COLOR} />,
          text: 'Compra a precio de mayoreo.',
        },
        {
          icon: <History16 color={WHITE_COLOR} />,
          text: 'Se tu propio jefe.',
        },
        {
          icon: <Calendar16 color={WHITE_COLOR} />,
          text: 'Maneja tus tiempos.',
        },
      ],
      image: {
        className: 'hero__image-reseller',
        height: 660,
        width: 700,
        src: `img_hero${deviceType === 'mobile' ? '-mobile' : ''}.png`,
      },
    };
  }

  return {
    title: 'Convertite en revendedor de Mercado Pago',
    items: [
      {
        icon: <Payment24 color={WHITE_COLOR} />,
        text: 'Comprá a precio mayorista',
      },
      {
        icon: <History24 color={WHITE_COLOR} />,
        text: 'Hacelo en 2 clicks',
      },
      {
        icon: <Calendar24 color={WHITE_COLOR} />,
        text: 'Maneja tus tiempos',
      },
      {
        icon: <StarOutlined24 color={WHITE_COLOR} />,
        text: 'No necesitás experiencia',
      },
      {
        className: 'hero__list-text',
        text: '¿Llegaste como referido?<br /> Comprá con 25% de descuento con tope de hasta $ 1.000. Te reintegramos el descuento a fin de mes.',
      },
    ],
    image: {
      className: 'hero__image-reseller',
      height: 660,
      width: 700,
      src:
        deviceType === 'mobile'
          ? 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/img_hero-mobile--c251fc08.png'
          : 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/img_hero--158506bf.png',
    },
  };
};
