// i18nLocalesOverride:['es-MX']

const { formatThousands } = require('../../../../../../services/utils/formatter');

function devicesConfig(devices) {
  let list = '';

  if (!devices || devices.length === 0) {
    return list;
  }

  devices?.map((device) => {
    list += `${device?.model}: $ ${formatThousands(device?.price_with_discount)}<br />`;

    return '';
  });

  return list;
}

function devicesList(devices, minPrice = false) {
  let list = '';

  if (!devices || devices.length === 0) {
    return list;
  }

  if (minPrice) {
    devices?.map((device) => {
      list += `<span class="list">${device?.model}: $ ${formatThousands(
        device?.price_with_discount,
      )}</span>`;

      return '';
    });

    return list;
  }

  devices?.map((device) => {
    list += `<span class="list">${device?.model}: $ ${formatThousands(
      device?.suggested_retail_price,
    )}</span>`;

    return '';
  });

  return list;
}

module.exports = (i18n, devices) => {
  return {
    title: i18n.gettext('Preguntas frecuentes'),
    faqs: [
      {
        title: i18n.gettext('¿En qué consiste el Programa?'),
        description: i18n.jsx.gettext(
          'El Programa de Representantes te permite convertir la venta de lectores Point en tu propio negocio.{0}{0}Al unirte, tendrás la oportunidad de:{0}{0}{1}{3}Comprar lectores a precios mayoristas:{4} genera ganancias aprovechando el margen de reventa de los Point.{2}{1}{3}Ganar premios en dinero:{4} participa por diversos premios cumpliendo ciertas metas, que recompensan el uso activo de los lectores.{2}{1}{3}Crecer en el programa:{4} mejora tu desempeño y sube de nivel para acceder a beneficios aún mayores.{2}',
          {
            tags: {
              0: '<br />',
              1: '<span class="list">',
              2: '</span>',
              3: '<strong>',
              4: '</strong>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Qué ventajas tendría como representante de Mercado Pago?'),
        description: i18n.jsx.gettext(
          'Siendo un representante de Mercado Pago, cuentas con:{0}{0}{1}Descuentos especiales y envío gratis en la compra de lectores.{2}{1}Ganancias con la reventa de los lectores más completas del mercado.{2}{1}Premios en dinero relacionados con tu desempeño.{2}{1}Flexibilidad para trabajar la hora y el día que quieras.{2}{1}Capacitaciones para ayudarte a aumentar tus ganancias y mejorar tu estrategia de venta.{2}',
          {
            tags: {
              0: '<br />',
              1: '<span class="list">',
              2: '</span>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Qué requisitos debo cumplir para ser representante?'),
        description: i18n.jsx.gettext(
          'Para unirte al Programa de Representantes y revender lectores Point, necesitas:{0}{0}{1}Tener al menos dieciocho (18) años si eres una persona física o representar a una persona moral constituida bajo las leyes de los Estados Unidos Mexicanos.{2}{1}Residir en México.{2}{1}Crear o tener una cuenta de Mercado Pago.{2}{0}{0}Para más información, consulta nuestros {3}Términos y condiciones{4}.',
          {
            tags: {
              0: '<br />',
              1: '<span class="list">',
              2: '</span>',
              3: '<a href="https://www.mercadopago.com.mx/ayuda/5251" target="_blank">',
              4: '</a>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Puedo tener un empleo fijo y participar en el programa?'),
        description: i18n.gettext(
          'Sí. Muchos representantes complementan su empleo fijo con la reventa de lectores Point, aprovechando la flexibilidad del programa para ganar ingresos adicionales. Tú decides tus horarios y cuánto tiempo dedicar como revendedor.',
        ),
      },
      {
        title: i18n.gettext('¿Cómo y cuándo puedo empezar a comprar lectores?'),
        description: i18n.gettext(
          'Puedes comenzar a comprar lectores Point en el Portal de Representantes tan pronto como completes tu registro en el programa.',
        ),
      },
      {
        title: i18n.gettext('¿A quién puedo vender lectores?'),
        description: i18n.gettext(
          `Puedes vender lectores Point a cualquier persona o comercio interesado en realizar transacciones de manera eficiente.`,
        ),
      },
      {
        title: i18n.gettext('¿Cómo puedo ganar los premios?'),
        description: i18n.gettext(
          'Para ganar los premios del programa, debes revender Point y motivar a tus clientes para que alcancen un mínimo en ventas con el lector que les vendiste, dentro de un plazo establecido.',
        ),
      },
      {
        title: i18n.gettext('¿Cuándo recibo el pago de mis premios?'),
        description: i18n.gettext(
          'Los premios que ganes de lunes a domingo serán pagados el viernes de la semana siguiente.',
        ),
      },
      {
        title: i18n.gettext('¿Cómo puedo entrenarme para vender?'),
        description: i18n.jsx.gettext(
          'Accede a nuestro entrenamiento en la Academia de Representantes y mejora tus ventas con tu líder de grupo, quien te será asignado al realizar tu primera compra.{0}{0}Además, recibirás un manual de entrenamiento, recursos útiles y estrategias de venta por WhatsApp tras tu registro y primera compra.',
          {
            tags: {
              0: '<br />',
            },
          },
        ),
      },
    ],
  };
};
