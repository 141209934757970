const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');

const Background = ({ deviceType = 'mobile' }) => {
  return (
    <Image
      alt=""
      src={deviceType === 'mobile' ? 'bg-white-wave-mobile.webp' : 'bg-white-wave.webp'}
      srcFallback={deviceType === 'mobile' ? 'bg-white-wave-mobile.png' : 'bg-white-wave.png'}
    />
  );
};

Background.propTypes = {
  deviceType: PropTypes.string,
};

module.exports = Background;
