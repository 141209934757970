// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Title, Text } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');
const Payment = require('@andes/icons/Payment24');
const Marquee = require('react-fast-marquee').default;

const Form = require('../../../../Organisms/Shared/Form');

const configs = require('./config');

const Hero = ({
  deviceType = 'mobile',
  traficOrigin,
  user = {
    blocked: false,
    email: '',
    fullName: '',
    isReseller: false,
    leaderId: 0,
    phone: '',
  },
  isWebview,
}) => {
  const { i18n } = useI18n();

  const { title, image, marquee } = configs(i18n, deviceType);

  const isMobile = deviceType === 'mobile';

  return (
    <>
      <div className="carousel-container">
        <Marquee autoFill>
          <div className="carousel-content">
            <Payment color="#FFF" />
            <Text color="inverted" weight="semibold">
              {marquee}
            </Text>
          </div>
        </Marquee>
      </div>
      <div className="hero-wrapper" data-version="form">
        <div className="hero-wrapper__container">
          <div className="hero-wrapper__container-texts">
            <Title className="hero-wrapper__title" component="h1" size="l" type="title">
              {title}
            </Title>
            {isMobile && (
              <Image
                preload
                alt={image.alt}
                lazyload="off"
                src={image.src}
                srcFallback={image.srcFallback}
                title={image.title}
              />
            )}
            <Form isWebview={isWebview} siteId="MLB" traficOrigin={traficOrigin} user={user} />
          </div>
        </div>
      </div>
    </>
  );
};

Hero.propTypes = {
  isWebview: PropTypes.bool,
  deviceType: PropTypes.string,
  traficOrigin: PropTypes.string,
  user: PropTypes.shape({
    blocked: PropTypes.bool,
    email: PropTypes.string,
    fullName: PropTypes.string,
    isReseller: PropTypes.bool,
    leaderId: PropTypes.number,
    phone: PropTypes.string,
  }),
};

module.exports = Hero;
