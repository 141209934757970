/* eslint-disable import/order */

const React = require('react');

const { useCallback, useEffect, useState } = React;

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/useFormContext');

const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const { Title } = require('@andes/typography');

const { getExternalURLs } = require('../../../../../../../services/utils/navigation');

const COUNTER_TIME = 5;

const SuccessWithAccount = () => {
  const [counter, setCounter] = useState(COUNTER_TIME);

  const { i18n } = useI18n();

  const { customerId, siteId, traficOrigin } = useFormContext();

  const images = [
    {
      src: 'animated-loading-first-image.webp',
      srcFallback: 'animated-loading-first-image.png',
    },
    {
      src: 'animated-loading-second-image.webp',
      srcFallback: 'animated-loading-second-image.png',
    },
  ];

  const externalUrls = getExternalURLs(siteId, traficOrigin);

  const redirectToMP = useCallback(() => {
    const custIdParam = `cust_id=${customerId}`;

    let baseUrl = externalUrls.ACCOUNT_CONFIRMATION;

    baseUrl += baseUrl.includes('?') ? `&${custIdParam}` : `?${custIdParam}`;

    window.open(baseUrl, '_self');
  }, [externalUrls, customerId]);

  useEffect(() => {
    const timer = setInterval(
      () =>
        setCounter((state) => {
          const newState = state - 1;

          return String(newState).padStart(2, '0');
        }),
      1000,
    );

    if (counter <= 0) {
      clearInterval(timer);

      return redirectToMP();
    }

    return () => clearInterval(timer);
  }, [counter, redirectToMP]);

  return (
    <div className="success-with-account">
      <div className="success-with-account__animation">
        <ProgressIndicatorCircular
          id="progress-redirect"
          modifier="block"
          size="xlarge"
          srLabel="Acceder a la cuenta"
          type="indeterminate"
        />
        {images.map((image, idx) => (
          <div key="image" id={`step-${idx}`}>
            <Image height={48} src={image.src} srcFallback={image.srcFallback} width={48} />
          </div>
        ))}
      </div>
      <Title className="block text-center" component="h6" size="xs">
        {i18n.gettext('Inicia sesión en tu cuenta de Mercado Pago')}
      </Title>
    </div>
  );
};

module.exports = SuccessWithAccount;
