// i18nLocalesOverride:['es-CL']

const React = require('react');

const { Text, Title } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');

const Header = () => {
  const { i18n } = useI18n();

  return (
    <div className="form__header">
      <Title className="bold" component="h2" size="l" type="title">
        {i18n.gettext('Regístrate en el Programa de Revendedores')}
      </Title>
      <Text component="p">
        {i18n.gettext('Si ya tienes una cuenta de Mercado Pago, no es necesario crear una nueva.')}
      </Text>
    </div>
  );
};

module.exports = Header;
