// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { Button } = require('@andes/button');

const configs = require('./config');

const ButtonFixed = ({ isLogged = false, siteId = 'MLA', deviceType = 'mobile' }) => {
  const { title } = configs(siteId, isLogged);

  let scrollPos;

  if (deviceType === 'mobile' && typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 1300) {
        document.getElementById('fixed-button').classList.add('has-scroll');
      }
      if (window.scrollY < scrollPos) {
        document.getElementById('fixed-button').classList.remove('has-scroll');
      }

      scrollPos = window.scrollY;
    });
  }

  return (
    <div className="button-fixed-wrapper" id="fixed-button">
      <Button hierarchy="loud" onClick={() => window.scrollTo(0, 750)}>
        {title}
      </Button>
    </div>
  );
};

ButtonFixed.propTypes = {
  isLogged: PropTypes.bool,
  siteId: PropTypes.string,
  deviceType: PropTypes.string,
};

module.exports = ButtonFixed;
