// i18nLocalesOverride:['es-AR','es-MX','es-CL']

module.exports = (siteId, i18n) => {
  if (siteId === 'MLC') {
    return {
      title: i18n.gettext('Compra a precios exclusivos'),
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'Compra con beneficios',
      text: 'Inversión mínima de 5 Point Blue.',
    };
  }

  return {
    title: 'Comprá con beneficios exclusivos',
    text: 'Vendé online o de forma presencial y generá ingresos por margen de venta.',
  };
};
