// i18nLocalesOverride:['pt-BR']

module.exports = (i18n) => {
  return {
    title: i18n.gettext('Acepta diversos medios de pago'),
    text: i18n.gettext('Y permite financiar en hasta 12 cuotas.'),
    items: [
      {
        alt: 'Visa',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/visa--b8fa3742.jpg',
        title: 'Visa',
      },
      {
        alt: 'Mastercard',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/master--0c055962.png',
        title: 'Mastercard',
      },
      {
        alt: 'Hipercard',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/hipercard--2d8b8c09.jpg',
        title: 'Hipercard',
      },
      {
        alt: 'Hiper',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/hiper--5e67893d.jpg',
        title: 'Hiper',
      },
      {
        alt: 'American Express',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/amex--190af761.jpg',
        title: 'American Express',
      },
      {
        alt: 'Diners Club',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/diners--204ab841.jpg',
        title: 'Diners Club',
      },
      {
        alt: 'Cabal',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/cabal--e2dcb155.jpg',
        title: 'Cabal',
      },
      {
        alt: 'Elo',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/elo--fbcd344f.jpg',
        title: 'Elo',
      },
      {
        alt: 'Sodexo',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/sodexo--ee846b23.jpg',
        title: 'Sodexo',
      },
      {
        alt: 'Pix',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/pix--a47b9259.jpg',
        title: 'Pix',
      },
      {
        alt: 'Alelo',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/alelo--a2a73669.jpg',
        title: 'Alelo',
      },
      {
        alt: 'Código QR',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/qr-code--00773df7.jpg',
        title: 'Código QR',
      },
      {
        alt: 'Apple Pay',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/apple-pay--45e1e522.jpg',
        title: 'Apple Pay',
      },
      {
        alt: 'Google Pay',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/google-pay--72d10f57.jpg',
        title: 'Google Pay',
      },
      {
        alt: 'Samsung Pay',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/samsung-pay--644ce2ad.jpg',
        title: 'Samsung Pay',
      },
      {
        alt: 'VR',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/vr--edfb2b66.jpg',
        title: 'VR',
      },
      {
        alt: 'Mercado Pago',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/mercado-pago--e14d7306.jpg',
        title: 'Mercado Pago',
      },
      {
        alt: 'Mercado Crédito',
        src: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/mercado-credito--b373badf.jpg',
        title: 'Mercado Crédito',
      },
    ],
  };
};
