// i18nLocalesOverride:['es-AR','es-MX','es-CL']

module.exports = (siteId, i18n) => {
  if (siteId === 'MLC') {
    return {
      title: i18n.gettext('Descubre cómo funciona el programa'),
      steps: [
        {
          id: 1,
          image: 'step-one.png',
          description: i18n.jsx.gettext(
            '{0}{2}Inscríbete en el formulario{3}{1} e inicia tu proceso de registro.',
            {
              tags: {
                0: '<strong>',
                1: '</strong>',
                2: '<a href="#form">',
                3: '</a>',
              },
            },
          ),
        },
        {
          id: 2,
          image: 'step-two.png',
          description: i18n.jsx.gettext(
            '{0}Compra a precio preferencial{1} y con envío gratuito.',
            {
              tags: {
                0: '<strong>',
                1: '</strong>',
              },
            },
          ),
        },
        {
          id: 3,
          image: 'step-three.png',
          description: i18n.jsx.gettext('{0}Gana revendiendo{1} y con los premios del programa', {
            tags: {
              0: '<strong>',
              1: '</strong>',
            },
          }),
        },
      ],
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'Conoce cómo funciona el Programa de Representantes',
      steps: [
        {
          id: 1,
          image: 'step-one.png',
          title: 'Compra con descuento',
          description: 'Accede a todos los modelos con precio de mayoreo y envío gratis.',
        },
        {
          id: 2,
          image: 'step-two.png',
          title: 'Obtén ganancias por venta',
          description: 'Vende las terminales a precio sugerido y obtén un margen de ganancias.',
        },
        {
          id: 3,
          image: 'step-three.png',
          title: 'Gana premios por tu desempeño',
          description: 'Alcanza metas por desempeño y gana dinero con los premios del programa.',
        },
      ],
    };
  }

  return {
    title: 'Conoce cómo funciona el Programa de Revendedores',
    subtitle: 'El programa es una manera simple de asegurarte dinero cada mes.',
    secondSubtitle:
      'Hacé tu primera compra y sumate a la experiencia de revender los productos de Mercado Pago.',
    steps: [
      {
        id: 1,
        image: 'step-one.png',
        description: i18n.jsx.gettext('{0}Inscribite{1} haciendo click y sé parte del programa.', {
          tags: {
            0: '<strong>',
            1: '</strong>',
          },
        }),
      },
      {
        id: 2,
        image: 'step-two.png',
        description: i18n.jsx.gettext(
          '{0}Comprá{1} a precio mayorista y con envío gratis adonde vos elijas.',
          {
            tags: {
              0: '<strong>',
              1: '</strong>',
            },
          },
        ),
      },
      {
        id: 3,
        image: 'step-three.png',
        description: i18n.jsx.gettext('{0}Salí{1} a revender y ganá premios con cada venta.', {
          tags: {
            0: '<strong>',
            1: '</strong>',
          },
        }),
      },
    ],
  };
};
