// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Title } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');

const CardDivider = require('../../../../Atoms/CardDivider');
const SliderCard = require('../../../../Organisms/Shared/SliderCard');

const configs = require('./config');
const Background = require('./background');

const Levels = ({ deviceType = 'mobile', prizesConfigs = [] }) => {
  const { i18n } = useI18n();

  const { title, items } = configs(i18n, prizesConfigs, deviceType);

  const imageSize =
    deviceType === 'mobile' ? { width: 288, height: 180 } : { width: 191, height: 180 };

  return (
    <div className="levels-wrapper">
      <Background deviceType={deviceType} position="top" />
      <div className="levels-wrapper__container">
        <div>
          <CardDivider />
          <Title component="h2" size="l" type="title">
            {title}
          </Title>
          <SliderCard
            deviceType={deviceType}
            imageSize={imageSize}
            items={items}
            label="Conheça os níveis do programa"
            mode="dark"
            type="transparent"
          />
        </div>
      </div>
      <Background deviceType={deviceType} position="bottom" />
    </div>
  );
};

Levels.propTypes = {
  deviceType: PropTypes.string,
  prizesConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      group_name: PropTypes.string,
      group_id: PropTypes.number,
      prizes: PropTypes.shape({
        device_activation_max_earning: PropTypes.number,
        new_level_bonus_amount: PropTypes.number,
        device_activation_earning_amount: PropTypes.number,
        tpv_percentage_earn_prize: PropTypes.number,
        tpv_max_earn_prize: PropTypes.number,
        max_prize_amount_per_month: PropTypes.number,
        min_investment: PropTypes.number,
      }),
    }),
  ),
};

module.exports = Levels;
