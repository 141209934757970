// i18nLocalesOverride:['es-MX']

const React = require('react');

const { Text, Title } = require('@andes/typography');

const Header = () => {
  return (
    <div className="form__header">
      <Title className="font-bold" component="h2" size="l" type="title">
        Comienza ahora
      </Title>
      <Text component="p">
        Completá el formulario con tus datos para registrarte en el programa.
      </Text>
    </div>
  );
};

module.exports = Header;
