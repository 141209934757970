// i18nLocalesOverride:['es-AR']

const React = require('react');

const { Typography } = require('@andes/typography');

const Header = () => {
  return (
    <div className="form__header">
      <Typography className="bold" component="h2" size="l" type="title">
        Comenzá a Revender ahora
      </Typography>
      <Typography component="p">
        Completá el formulario con tus datos para registrarte en el programa.
      </Typography>
    </div>
  );
};

module.exports = Header;
