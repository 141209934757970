/* eslint-disable import/order */

const React = require('react');

const { useCallback, useRef } = React;

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/useFormContext');

const { Button } = require('@andes/button');
const { Card } = require('@andes/card');
const { Form } = require('@andes/form');
const { TextField } = require('@andes/textfield');
const { Text, Title } = require('@andes/typography');

const PillEmailLogged = require('../../PillEmailLogged');
const Recaptcha = require('../../../../../Molecules/Recaptcha');
const Tyc = require('../Tyc');

const masks = require('@andes/textfield/build/masks');
const useMask = require('@andes/textfield/build/hooks/useMask');

const validators = require('../../utils/validators');
const tracking = require('../../../../../../../services/utils/tracking');

const LoggedUser = () => {
  const { i18n } = useI18n();

  const {
    errors,
    isWebview,
    onSetErrors,
    onSetPhone,
    onSetTyc,
    recaptchaError,
    showErrors,
    user,
    validateForm,
  } = useFormContext();

  const hasPhoneError = showErrors && errors?.includes('PHONE');
  const hasRecaptchaError = showErrors && recaptchaError;

  const phoneFieldRef = useRef();
  const username = user?.name?.split(' ')[0] || user?.name;

  useMask(phoneFieldRef, { maskOptions: masks.BR.MOBILE });

  const fieldInteractionTracking = (field) => tracking('home', `form-${field}-interaction`);

  const handleError = useCallback(
    (action = 'add', key) => {
      let newErrors = errors;

      if (action === 'remove') {
        newErrors = errors.length ? errors.filter((er) => er !== key) : [];
      } else {
        newErrors.push(key);
      }

      onSetErrors(newErrors);
    },
    [errors, onSetErrors],
  );

  const validatePhone = React.useCallback(
    (event) => {
      const { value } = event.target;

      const isValid = validators.validatePhone(value);

      handleError(isValid ? 'remove' : 'add', 'PHONE');

      onSetPhone(value);
    },
    [handleError, onSetPhone],
  );

  const handleChangeTyc = () => {
    handleError('remove', 'TYC');

    onSetTyc((state) => !state);

    tracking('home', 'click-on-terms-and-conditions');
  };

  const submit = async (e) => {
    tracking('home', 'form-register-i-want-to-be-a-reseller');

    validateForm(e);
  };

  return (
    <Card className="logged-wrapper">
      <Title className="mt-0 block text-center" component="h2">
        {i18n.pgettext('resellers-landing', '¡Hola, {0}!', username)}
      </Title>

      <Text className="text-center" component="p">
        {i18n.gettext('Completa los campos para participar en el programa.')}
      </Text>

      {!isWebview && <PillEmailLogged />}

      <Form onSubmit={submit}>
        <TextField
          ref={phoneFieldRef}
          defaultValue={user.phone}
          helper={hasPhoneError ? 'Revise seu número de WhatsApp.' : ''}
          modifier={hasPhoneError ? 'error' : undefined}
          placeholder={i18n.gettext('Número de WhatsApp')}
          srLabel={i18n.gettext('Número de WhatsApp')}
          onBlur={({ target }) => {
            if (target.value) {
              fieldInteractionTracking('phone');
            }
          }}
          onChange={validatePhone}
        />

        <Tyc isChecked={user.tyc} onHandleChange={handleChangeTyc} />

        <Recaptcha
          countryId="BR"
          errors={
            hasRecaptchaError
              ? [i18n.gettext('Es obligatorio rellenar este campo para avanzar.')]
              : []
          }
        />

        <Button fullWidth type="submit">
          {i18n.gettext('Registrarse')}
        </Button>
      </Form>
    </Card>
  );
};

module.exports = LoggedUser;
