// i18nLocalesOverride:['es-AR','es-MX','es-CL','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Typography } = require('@andes/typography');
const ChevronUp16 = require('@andes/icons/ChevronUp16');
const ChevronDown16 = require('@andes/icons/ChevronDown16');

const tracking = require('../../../../services/utils/tracking');

const ItemDetails = ({ items = [], onclick }) => {
  const handleCollapsed = (index) => {
    const collapsibles = document.getElementsByClassName('collapsible-card');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < collapsibles.length; i++) {
      if (i === index) {
        collapsibles[+i].classList.toggle('collapsed');
      }
    }
  };

  return items?.length > 0
    ? items.map((item, index) => (
        <div key={item.title} className="collapsible-card collapsed">
          <div
            className="collapsible-card__title"
            role="button"
            tabIndex={-1}
            onClick={() => {
              tracking('home', `faq-${item.title}`);

              handleCollapsed(index);

              if (onclick) {
                onclick();
              }
            }}
            onKeyDown={() => {}}
          >
            <Typography size="xs" type="title">
              {item?.title}
            </Typography>
            <div className="chevron-down">
              <ChevronDown16 />
            </div>
            <div className="chevron-up">
              <ChevronUp16 />
            </div>
          </div>
          <div className="collapsible-card__content">
            <Typography>{item.description}</Typography>
          </div>
        </div>
      ))
    : '';
};

ItemDetails.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  onclick: PropTypes.func,
};

module.exports = ItemDetails;
